import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/guard/auth.guard';
import { UserResolver } from './resolvers/user.resolver';

const routes: Routes = [
  {
    path: 'home',
    pathMatch: 'full',
    redirectTo: 'dubai'
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dubai'
  },
  {
    path: 'dubai',
    loadChildren: () => import('./modules/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'home/:section',
    loadChildren: () => import('./modules/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/profile-page/profile-page.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'addresses/:state',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/address-page/address-page.module').then(m => m.AddressPageModule)
  },
  {
    path: 'my-task',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/my-task/my-task.module').then(m => m.MyTaskModule)
  },
  {
    path: 'post-task/:id/:type',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./modules/post-task/post-task.module').then(m => m.PostTaskModule)
  },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./modules/profile-page/profile-page.module').then(m => m.ProfilePageModule)
  // },
  {
    path: 'tnc',
    loadChildren: () => import('./modules/tnc-page/tnc-page.module').then(m => m.TncPageModule)
  },
  {
    path: 'maid-service/:id',
    // loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    loadChildren: () => import('./modules/quickbookings/preferred-maid/preferred-maid.module').then(m => m.PreferredMaidModule),
    data: { serviceName: 'Maid' }
  },
  {
    path: ':city/maid-service/:id',
    // loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    loadChildren: () => import('./modules/quickbookings/preferred-maid/preferred-maid.module').then(m => m.PreferredMaidModule),
    data: { serviceName: 'Maid' }
  },
  {
    path: ':city/ac-cleaning/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'AC Cleaning' }
  },
  /* {
    path: ':city/expert-gardeners/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'Expert Gardeners' }
  }, */
  /* {
    path: ':city/gas-booking/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'Gas Booking' }
  }, */
  {
    path: ':city/pcr-test-at-home/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'PCR Test' }
  },
  {
    path: ':city/express-pcr-test-at-home/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'Express PCR Test' }
  },
  {
    path: ':city/disinfection-service/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'Disinfection Service' }
  },
  {
    path: ':city/deep-cleaning/:id',
    loadChildren: () => import('./modules/maid-service/maid-service.module').then(m => m.MaidServiceModule),
    data: { serviceName: 'Deep Cleaning' }
  },
  {
    path: 'quick-bookings/:id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/quick-bookings-page/quick-bookings-page.module').then(m => m.QuickBookingsPageModule)
  },
  {
    path: 'quick-bookings/:id/:step',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/quick-bookings-page/quick-bookings-page.module').then(m => m.QuickBookingsPageModule)
  },
  {
    path: 'payment/:status/:bookingId',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/payment-success-page/payment-success-page.module').then(m => m.PaymentSuccessPageModule),
    resolve: { user: UserResolver }
  },
  {
    path: ':city/services',
    loadChildren: () => import('./modules/our-services/our-services.module').then(m => m.OurServicesModule)
  },
  {
    path: ':city/services/details/:id',
    loadChildren: () => import('./modules/our-services/our-services.module').then(m => m.OurServicesModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'partner-with-us',
    loadChildren: () => import('./modules/partner-with-us/partner-with-us.module').then(m => m.PartnerWithUsModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./modules/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'customer-addresses/:state',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/customer-address/customer-address.module').then(m => m.CustomerAddressModule)
  },
  // {
  //   path: 'ramadan',
  //   loadChildren: () => import('./modules/landing-pages/ramadan-service/ramadan-service.module').then(m => m.RamadanServiceModule)
  // },
  {
    path: ':city/pest-control/:id',
    loadChildren: () => import('./modules/quickbookings/pest-control/pest-control.module').then(m => m.PestControlModule)
  },
  {
    path: ':city/furniture-cleaning/:id',
    loadChildren: () => import('./modules/quickbookings/furniture-clean/furniture-clean.module').then(m => m.FurnitureCleanModule)
  },
  /* {
    path: ':city/car-wash/:id',
    loadChildren: () => import('./modules/quickbookings/car-wash/car-wash.module').then(m => m.CarWashModule)
  }, */
  {
    path: ':city/salon-men/:id',
    loadChildren: () => import('./modules/quickbookings/salon-men/salon-men.module').then(m => m.SalonMenModule)
  },
  {
    path: ':city/salon-women/:id',
    loadChildren: () => import('./modules/quickbookings/salon-women/salon-women.module').then(m => m.SalonWomenModule)
  },
  {
    path: ':city/handyman-service/:id',
    loadChildren: () => import('./modules/quickbookings/handyman/handyman.module').then(m => m.HandymanModule)
  },
  {
    path: ':city/plumber-service/:id',
    loadChildren: () => import('./modules/quickbookings/handyman/handyman.module').then(m => m.HandymanModule)
  },
  {
    path: ':city/electrician-service/:id',
    loadChildren: () => import('./modules/quickbookings/handyman/handyman.module').then(m => m.HandymanModule)
  },
  {
    path: ':city/executive-blood-tests/:id',
    loadChildren: () => import('./modules/quickbookings/labtest/labtest.module').then(m => m.LabtestModule)
  },
  {
    path: ':city/zap-maid-service/:id',
    loadChildren: () => import('./modules/quickbookings/zap-maid/zap-maid.module').then(m => m.ZapMaidModule)
  },
  {
    path: ':city/:service/add-ons/:id',
    loadChildren: () => import('./modules/quickbookings/shared-pages/addons-page/addons-page.module').then(m => m.AddonsPageModule)
  },

  // MAID SERVICE LANDING PAGES
  {
    path: 'dubai-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/maid-service.module').then(m => m.MaidServiceModule)
  },
  {
    path: 'dubai-jumeirah-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/location-pages/jumeirah/jumeirah.module').then(m => m.JumeirahModule)
  },
  {
    path: 'dubai-jumeirah-beach-residence-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/location-pages/jumeirah-beach/jumeirah-beach.module').then(m => m.JumeirahBeachModule)
  },
  {
    path: 'dubai-jumeirah-lake-towers-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/location-pages/jumeirah-lake/jumeirah-lake.module').then(m => m.JumeirahLakeModule)
  },
  {
    path: 'dubai-dubai-marina-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/location-pages/dubai-marina/dubai-marina.module').then(m => m.DubaiMarinaModule)
  },
  {
    path: 'dubai-al-barsha-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/location-pages/al-barsha/al-barsha.module').then(m => m.AlBarshaModule)
  },
  {
    path: 'dubai-downtown-dubai-maid-service',
    loadChildren: () => import('./modules/landing-pages/maid-service/location-pages/downtown-dubai/downtown-dubai.module').then(m => m.DowntownDubaiModule)
  },

  // DEEP CLEANING LANDING PAGES
  {
    path: 'dubai-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/deep-clean.module').then(m => m.DeepCleanModule)
  },
  {
    path: 'dubai-jumeirah-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/location-pages/jumeirah/jumeirah.module').then(m => m.JumeirahModule)
  },
  {
    path: 'dubai-jumeirah-beach-residence-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/location-pages/jumeirah-beach/jumeirah-beach.module').then(m => m.JumeirahBeachModule)
  },
  {
    path: 'dubai-downtown-dubai-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/location-pages/downtown-dubai/downtown-dubai.module').then(m => m.DowntownDubaiModule)
  },
  {
    path: 'dubai-jumeirah-lake-towers-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/location-pages/jumeirah-lake/jumeirah-lake.module').then(m => m.JumeirahLakeModule)
  },
  {
    path: 'dubai-al-barsha-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/location-pages/al-bashra/al-bashra.module').then(m => m.AlBashraModule)
  },
  {
    path: 'dubai-dubai-marina-deep-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/deep-clean/location-pages/dubai-marina/dubai-marina.module').then(m => m.DubaiMarinaModule)
  },

  // AC CLEANING LANDING PAGES
  {
    path: 'dubai-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/ac-cleaning.module').then(m => m.AcCleaningModule)
  },
  {
    path: 'dubai-dubai-marina-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/location-pages/dubai-marina/dubai-marina.module').then(m => m.DubaiMarinaModule)
  },
  {
    path: 'dubai-jumeirah-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/location-pages/jumeirah/jumeirah.module').then(m => m.JumeirahModule)
  },
  {
    path: 'dubai-jumeirah-beach-residence-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/location-pages/jumeirah-beach/jumeirah-beach.module').then(m => m.JumeirahBeachModule)
  },
  {
    path: 'dubai-downtown-dubai-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/location-pages/downtown-dubai/downtown-dubai.module').then(m => m.DowntownDubaiModule)
  },
  {
    path: 'dubai-al-barsha-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/location-pages/al-bashra/al-bashra.module').then(m => m.AlBashraModule)
  },
  {
    path: 'dubai-jumeirah-lake-towers-ac-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/ac-cleaning/location-pages/jumeirah-lake/jumeirah-lake.module').then(m => m.JumeirahLakeModule)
  },

  // FURNITURE CLEANING LANDING PAGES
  {
    path: 'dubai-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/furniture-clean.module').then(m => m.FurnitureCleanModule)
  },
  {
    path: 'dubai-dubai-marina-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/location-pages/dubai-marina/dubai-marina.module').then(m => m.DubaiMarinaModule)
  },
  {
    path: 'dubai-jumeirah-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/location-pages/jumeirah/jumeirah.module').then(m => m.JumeirahModule)
  },
  {
    path: 'dubai-jumeirah-beach-residence-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/location-pages/jumeirah-beach/jumeirah-beach.module').then(m => m.JumeirahBeachModule)
  },
  {
    path: 'dubai-downtown-dubai-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/location-pages/downtown-dubai/downtown-dubai.module').then(m => m.DowntownDubaiModule)
  },
  {
    path: 'dubai-al-barsha-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/location-pages/al-bashra/al-bashra.module').then(m => m.AlBashraModule)
  },
  {
    path: 'dubai-jumeirah-lake-towers-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-clean/location-pages/jumeirah-lake/jumeirah-lake.module').then(m => m.JumeirahLakeModule)
  },

  // CUSTOM FURNITURE CLEANING LANDING PAGES
  {
    path: 'dubai-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/furniture-custom.module').then(m => m.FurnitureCustomModule)
  },
  {
    path: 'dubai-dubai-marina-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/location-pages/dubai-marina/dubai-marina.module').then(m => m.DubaiMarinaModule)
  },
  {
    path: 'dubai-jumeirah-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/location-pages/jumeirah/jumeirah.module').then(m => m.JumeirahModule)
  },
  {
    path: 'dubai-jumeirah-beach-residence-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/location-pages/jumeirah-beach/jumeirah-beach.module').then(m => m.JumeirahBeachModule)
  },
  {
    path: 'dubai-downtown-dubai-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/location-pages/downtown-dubai/downtown-dubai.module').then(m => m.DowntownDubaiModule)
  },
  {
    path: 'dubai-al-barsha-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/location-pages/al-bashra/al-bashra.module').then(m => m.AlBashraModule)
  },
  {
    path: 'dubai-jumeirah-lake-towers-custom-furniture-cleaning-service',
    loadChildren: () => import('./modules/landing-pages/furniture-custom/location-pages/jumeirah-lake/jumeirah-lake.module').then(m => m.JumeirahLakeModule)
  },
  {
    path: 'referral',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/referral/referral.module').then(m => m.ReferralModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./modules/more-page/more-page.module').then(m => m.MorePageModule)
  },
  // {
  //   path: ':city/ramadan-home-cleaning/:id',
  //   loadChildren: () => import('./modules/quickbookings/ramadan/ramadan.module').then(m => m.RamadanModule)
  // },
  {
    path: 'alfutaim-blue',
    loadChildren: () => import('./modules/alfutaim-blue/alfutaim-blue.module').then(m => m.AlfutaimBlueModule)
  },
  {
    path: 'holiday-homes',
    loadChildren: () => import('./modules/holiday-homes/holiday-homes.module').then(m => m.HolidayHomesModule)
  }, 
  // {
  //   path: 'bookings/payment',
  //   loadChildren: () => import('./modules/cod-to-cc-payment/cod-to-cc-payment.module').then(m => m.CodToCcPaymentModule)
  // },


  /* {
    path: 'dubai-deep-cleaning-service',
    loadChildren: () => import('./landing-pages/deep-clean/deep-clean.module').then(m => m.DeepCleanModule)
  },
  {
    path: 'dubai-ac-cleaning-service',
    loadChildren: () => import('./landing-pages/ac-cleaning/ac-cleaning.module').then(m => m.AcCleaningModule)
  },
  {
    path: 'dubai-furniture-cleaning-service',
    loadChildren: () => import('./landing-pages/furniture-clean/furniture-clean.module').then(m => m.FurnitureCleanModule)
  },
  {
    path: 'dubai-custom-furniture-cleaning',
    loadChildren: () => import('./landing-pages/furniture-custom/furniture-custom.module').then(m => m.FurnitureCustomModule)
  }, */
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
