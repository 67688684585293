export interface ISubCategory {
  id: string;
  bannerImage: string;
  bannerTc: string;
  categoryDetails: [];
  categoryIds: string[];
  code: string;
  createdAt: firebase.default.firestore.Timestamp;
  displayOrder: number;
  image: string;
  imageNew: string;
  isActive: boolean;
  isQuickBooking: boolean;
  quickDisplayOrder: number;
  selectedStaff: any;
  requirement: {
    headerNote: string;
    headerNoteWeb: string;
    itemType: any;
    title: string;
    whatsInclude: string;
    categories: any[];
    categoryHeaders: [];
    minimumOrderValueDetails: any;
    mainCategoryTypeId: any;
    moreInfo: string;
    packageDetails: any;
    includeDetails: any;
    preferredStaff: any;
    uploadDetails: {
      count: any;
      imageCount: any;
      isActive: any;
    };
  },
  subCategoryName: string;
  timeSlots: any;
  newType: number;
  directGroupDisplayOrder: number;
  focusGroupDisplayOrder: number;
  isFocusGroup: boolean;
  displayPrice: string;
  inAppBanner: {
    content: string;
    expiredAt: firebase.default.firestore.Timestamp;
    images: string[];
    isActive: boolean;
    title: string;
    type: number;
  }
  serviceBanners: [{
    image: string,
    isActive: boolean,
    tc: string
  }];
  serviceBannersWeb: [{
    image: string,
    isActive: boolean,
    tc: string
  }];
  paymentMethod: any[];
  timeSlotMaxDays: number;
  servicePolygons: any[];
  specialTimeSlots: any;
  timeSlotMaximum: number;
  vendorList: [];
  iconName: string;
  customSubCategory: {
    id: string,
    subCategoryName: string
  };
  tipDetails: {
    isActive: boolean,
    title: string,
    subTitle: string,
    priceBreakDown: { value: string, key: string },
    tips: [{
      tip: string,
      value: number,
      sort: number,
      isActive: boolean,
      popular: boolean,
      isPrefer: boolean
    }];
  };
  serviceInfo: any;
  locationCheck: boolean;
  serviceDisableArea: any[];
  show?: boolean;
  popular?: boolean;
  packageDetails: MonthlyPackageDetails;
  monthlyPackageExcludeZones: any[];
  monthlyPackageExcludeZonesNames: any[];
}

export interface MonthlyPackageDetails {
  defaultFrequencyId: string;
  frequencies: MonthlyPackageFrequency[];
  isActive: boolean;
  jobStartTimeGapDays: number;
  packages: MonthlyPackage[];
  preferredPackageId: string;
  preferredDetails: PreferredDetails;
  paymentDetails?: any;
  priceDetails: any;
  serviceBanners: any[];
  termsCondition: string;
  unsupportItems: string[];
};

export interface MonthlyPackageFrequency {
  itemId: string;
  itemName: string;
  visitsPerWeekText?: string;
  visitsPerWeek: number;
  isPreferred?: boolean;
  isActive?: boolean;
  itemPrice?: number;
  perVisitMinutes?: number;
  subTitle?: string;
  description: string;
  priceDetails: any;
}

export interface MonthlyFrequencyPriceDetails {
  discount: number;
  discountType: string;
  discountText: string
  convenienceFee: number;
  discountText_ar: string;
}

export interface MonthlyPackage {
  itemId: string,
  packageName: string,
  isActive: boolean,
  isPreferred: boolean,
  weekCount: number
}

export interface MonthlyDaySlot {
  day: string;
  slot: string;
  weekday: number;
  slots?: any;
}

export interface PreferredDetails {
  packageId: string;
  frequencyId: string;
  isActive: boolean;
  name: string;
  label: string;
  image: string;
  isPopularService: boolean;
  iconName: string;
}


export class SubCategory {
  id: string;
  bannerImage: string;
  bannerTc: string;
  categoryDetails: [];
  categoryIds: string[];
  code: string;
  createdAt: firebase.default.firestore.Timestamp;
  displayOrder: number;
  image: string;
  imageNew: string;
  isActive: boolean;
  isQuickBooking: boolean;
  quickDisplayOrder: number;
  selectedStaff: any;
  requirement: {
    headerNote: string;
    headerNoteWeb: string;
    itemType: any;
    title: string;
    whatsInclude: string;
    categories: any[];
    categoryHeaders: [];
    minimumOrderValueDetails: any;
    mainCategoryTypeId: any;
    moreInfo: string;
    packageDetails: any;
    includeDetails: any;
    preferredStaff: any;
    uploadDetails: {
      count: any;
      imageCount: any;
      isActive: boolean;
    };
  };
  subCategoryName: string;
  timeSlots: any;
  newType: number;
  directGroupDisplayOrder: number;
  focusGroupDisplayOrder!: number;
  isFocusGroup: boolean;
  displayPrice: string;
  inAppBanner: {
    content: string;
    expiredAt: firebase.default.firestore.Timestamp;
    images: string[];
    isActive: boolean;
    title: string;
    type: number;
  };
  serviceBanners: [{
    image: string,
    isActive: boolean,
    tc: string
  }];
  serviceBannersWeb: [{
    image: string,
    isActive: boolean,
    tc: string
  }];
  paymentMethod: any[];
  timeSlotMaxDays: number;
  servicePolygons: any[];
  specialTimeSlots: any;
  timeSlotMaximum: number;
  vendorList: [];
  iconName: string;
  customSubCategory: {
    id: string,
    subCategoryName: string
  };
  tipDetails: {
    isActive: boolean,
    title: string,
    subTitle: string,
    priceBreakDown: { value: string, key: string },
    tips: [{
      tip: string,
      value: number,
      sort: number,
      isActive: boolean,
      popular: boolean,
      isPrefer: boolean
    }];
  };
  serviceInfo: any;
  locationCheck: boolean;
  serviceDisableArea: any[];
  show?: boolean;
  popular?: boolean;
  packageDetails: MonthlyPackageDetails;
  monthlyPackageExcludeZones: any[];
  monthlyPackageExcludeZonesNames: any[];

  constructor(data: ISubCategory) {
    this.id = data.id;
    this.bannerImage = data.bannerImage;
    this.bannerTc = data.bannerTc;
    this.categoryDetails = data.categoryDetails;
    this.categoryIds = data.categoryIds;
    this.code = data.code;
    this.createdAt = data.createdAt;
    this.displayOrder = data.displayOrder;
    this.image = data.image;
    this.imageNew = data.imageNew;
    this.isActive = data.isActive;
    this.selectedStaff = data.selectedStaff;
    this.isQuickBooking = data.isQuickBooking;
    this.quickDisplayOrder = data.quickDisplayOrder;
    this.requirement = data.requirement;
    this.subCategoryName = data.subCategoryName;
    this.timeSlots = data.timeSlots;
    this.newType = data.newType;
    this.directGroupDisplayOrder = data.directGroupDisplayOrder;
    this.focusGroupDisplayOrder = data.focusGroupDisplayOrder;
    this.isFocusGroup = data.isFocusGroup;
    this.displayPrice = data.displayPrice;
    this.inAppBanner = data.inAppBanner;
    this.serviceBanners = data.serviceBanners;
    this.serviceBannersWeb = data.serviceBannersWeb
    this.paymentMethod = data.paymentMethod;
    this.timeSlotMaxDays = data.timeSlotMaxDays;
    this.servicePolygons = data.servicePolygons;
    this.specialTimeSlots = data.specialTimeSlots;
    this.timeSlotMaximum = data.timeSlotMaximum;
    this.vendorList = data.vendorList;
    this.iconName = data.iconName;
    this.customSubCategory = data.customSubCategory;
    this.tipDetails = data.tipDetails;
    this.serviceInfo = data.serviceInfo;
    this.locationCheck = data.locationCheck;
    this.serviceDisableArea = data.serviceDisableArea;
    this.show = data.show;
    this.popular = data.popular;
    this.packageDetails = data.packageDetails;
    this.monthlyPackageExcludeZones=data.monthlyPackageExcludeZones;
    this.monthlyPackageExcludeZonesNames=data.monthlyPackageExcludeZonesNames;
  }

  get cCode() {
    return this.code;
  }
}
