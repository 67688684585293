import { Injectable } from '@angular/core';
import { Address } from '../models/address.model';
import { SubCategory } from '../models/subCategory.model';
import { StaffMember } from '../models/staffMember.model';
import { Holiday } from '../models/holiday.model';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  defaultServicePolygon = [
    { "lat": 25.09058, "lng": 55.45314 },
    { "lat": 25.08964, "lng": 55.44794 },
    { "lat": 25.08248, "lng": 55.43381 },
    { "lat": 25.05013, "lng": 55.42685 },
    { "lat": 25.05137, "lng": 55.4081 },
    { "lat": 25.05043, "lng": 55.39245 },
    { "lat": 25.04669, "lng": 55.37885 },
    { "lat": 25.00145, "lng": 55.33013 },
    { "lat": 24.97509, "lng": 55.31298 },
    { "lat": 24.96845, "lng": 55.27565 },
    { "lat": 24.9534, "lng": 55.23678 },
    { "lat": 24.9735, "lng": 55.14447 },
    { "lat": 24.94543, "lng": 55.13041 },
    { "lat": 24.91953, "lng": 55.10948 },
    { "lat": 24.88453, "lng": 55.06828 },
    { "lat": 24.93268, "lng": 55.06794 },
    { "lat": 24.95065, "lng": 55.02263 },
    { "lat": 24.97391, "lng": 54.98006 },
    { "lat": 25.01545, "lng": 54.928 },
    { "lat": 25.06786, "lng": 54.99233 },
    { "lat": 25.12848, "lng": 55.09627 },
    { "lat": 25.20709, "lng": 55.21154 },
    { "lat": 25.35764, "lng": 55.29385 },
    { "lat": 25.32774, "lng": 55.35468 },
    { "lat": 25.31326, "lng": 55.34853 },
    { "lat": 25.29939, "lng": 55.35165 },
    { "lat": 25.30228, "lng": 55.35829 },
    { "lat": 25.29905, "lng": 55.37191 },
    { "lat": 25.29985, "lng": 55.37678 },
    { "lat": 25.30376, "lng": 55.38474 },
    { "lat": 25.29657, "lng": 55.40311 },
    { "lat": 25.28696, "lng": 55.42792 },
    { "lat": 25.25589, "lng": 55.514 },
    { "lat": 25.23691, "lng": 55.56422 },
    { "lat": 25.17915, "lng": 55.56266 },
    { "lat": 25.09897, "lng": 55.46035 }
  ];

  constructor() { }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude })
      },
        err => {
          reject(err)
        });
    });
  }

  isValidLocation(lat: number, lng: number, servicePolygons: any[]): boolean {
    var polygonList: any[] = [];
    var isInside = false;
    var isInRange = false;

    if (servicePolygons && servicePolygons?.length > 0) {
      servicePolygons.forEach((e) => {
        for (var poly in e) {
          polygonList.push(e[poly]);
        }
      })
    }

    if (polygonList.length < 1) {
      polygonList.push(this.defaultServicePolygon);
    }

    polygonList.forEach((e) => {
      if (!isInside) {
        var polygon = e;
        var minX = polygon[0].lat, maxX = polygon[0].lat;
        var minY = polygon[0].lng, maxY = polygon[0].lng;
        for (var n = 1; n < polygon.length; n++) {
          var q = polygon[n];
          minX = Math.min(q.lat, minX);
          maxX = Math.max(q.lat, maxX);
          minY = Math.min(q.lng, minY);
          maxY = Math.max(q.lng, maxY);
        }

        if (lat < minX || lat > maxX || lng < minY || lng > maxY) {
          isInRange = false;
        } else {
          isInRange = true;
        }

        if (isInRange) {
          for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            if ((polygon[i].lng > lng) != (polygon[j].lng > lng) &&
              lat < (polygon[j].lat - polygon[i].lat) * (lng - polygon[i].lng) / (polygon[j].lng - polygon[i].lng) + polygon[i].lat) {
              isInside = !isInside;
            }
          }
        }
      }
    });
    return isInRange ? isInside : false;
  }

  isVaildServiceAddress(addresses: Address[], servicePolygons: any[]): boolean {
    var validAddressList = [];
    addresses?.forEach((e) => {
      var geo = e?.placeDetails?.geo as any;
      if (!geo?.lat && e?.currentGeo?.longitude) {
        geo = { 'lat': e?.currentGeo?.latitude, 'lng': e?.currentGeo?.longitude };
      }
      if (geo && this.isValidLocation(geo?.lat, geo?.lng, servicePolygons))
        validAddressList.push(e);
    })
    if (validAddressList.length > 0)
      return true;
    return false;
  }

  getValidServiceList(address: Address, subCategories: SubCategory[]) {
    if (address && address?.currentGeo && subCategories?.length > 0) {
      const lat = address?.currentGeo?.latitude;
      const lng = address?.currentGeo?.longitude;
      let validServiceList = [] as SubCategory[];
      subCategories?.forEach((e) => {
        if (e.servicePolygons && this.isValidLocation(lat, lng, e?.servicePolygons)) {
          validServiceList.push(e);
        }
      });
      return validServiceList;
    }
    return [];
  }

  isZapAvailableLocation(crntAddress: Address, subCategory: SubCategory): boolean {
    const lat = crntAddress?.currentGeo?.latitude;
    const lng = crntAddress?.currentGeo?.longitude;
    const servicePolys = subCategory?.servicePolygons;

    if (lat && lng && servicePolys?.length)
      return this.isValidLocation(lat, lng, servicePolys) ?? false;
    return false;
  }

  isStaffAvailableLocation(crntAddress: Address, staffMember: StaffMember): boolean {
    const lat = crntAddress?.currentGeo?.latitude;
    const lng = crntAddress?.currentGeo?.longitude;
    const servicePolys = staffMember?.servicePolygons;

    if (lat && lng && servicePolys?.length)
      return this.isValidLocation(lat, lng, servicePolys) ?? false;
    return true;
  }

  isDisabledLocation(lat: number, lng: number, servicePolys: any) {
    return this.isValidLocation(lat, lng, servicePolys);
  }

  isHolidayAvailableLocation(crntAddress: Address, holiday: Holiday): boolean {
    const lat = crntAddress?.currentGeo?.latitude;
    const lng = crntAddress?.currentGeo?.longitude;
    const servicePolys = holiday?.servicePolygons;

    if (lat && lng && servicePolys?.length)
      return this.isValidLocation(lat, lng, servicePolys) ?? false;
    return true;
  }

}
