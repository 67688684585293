import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit {

  @Input() icon!: string;
  @Input() title!: string;
  @Input() desc!: string;
  @Input() buttonText!: string;
  @Input() hasProceedBtn: boolean = false;
  @Input() proceedBtnText!: string;
  @Input() path!: string;

  constructor(public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit(): void {
  }

  public submit() {
    if (this.path) this.router.navigate([this.path]);
    this.activeModal.close(this.path ? false : true);
  }
}
