import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
// import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { CountdownEvent } from 'ngx-countdown';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { SignupLeadsService } from 'src/app/services/signup-leads.service';
import { getMobileNumber } from 'src/app/shared/logics/mobileNumberModifier';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isDirectLogin: boolean = false;
  phoneNumber: string = '';
  showLoader: boolean = false;
  subscription: Subscription | undefined;
  otpSentSubscription: Subscription | undefined;
  otpVerifiedSubscription: Subscription | undefined;
  isOtpSent: boolean = false;
  isOtpResend: boolean = false;
  showOtpScreen: boolean = false;
  isOtpVerified: boolean = false;
  // otpDigits = ['digit1', 'digit2', 'digit3', 'digit4', 'digit5', 'digit6'];
  isDisableResend = true;
  // @ViewChildren('otpFormRow') rows: any;
  displayName: string = '';
  welcomeText: string = 'Welcome to YellowZap!';
  countDown!: Subscription;
  counter: number = 30;
  tick = 1000;
  isResendAtempted: boolean = false;

  // keyCode: string = 'none';
  submitted: boolean = false;
  phoneSubmitted: boolean = false;
  flagUrl: string = 'assets/flags/ae.svg';

  // otpForm = this.fb.group({
  //   digit1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
  //   digit2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
  //   digit3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
  //   digit4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
  //   digit5: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
  //   digit6: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]]
  // });

  phoneForm = this.fb.group({
    countryCode: ['+971', Validators.required],
    phoneNumber: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]]
  })

  otpString: string = '';
  // otpArray: string[] = [];
  // otpInputConfig: NgxOtpInputConfig = {
  //   otpLength: 6,
  //   autofocus: true,
  // };

  /* @ViewChild(NgOtpInputComponent, { static: false })
  ngOtpInput!: NgOtpInputComponent;
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: ''
  }; */
  showError: boolean = false;
  isNumberChanged: boolean = false;

  constructor(
    private authService: AuthService,
    private data: DataService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private leadService: SignupLeadsService
  ) { }

  ngOnInit(): void {
    this.subscription = this.data.crntShowOtpLoader.subscribe(showLoader => this.showLoader = showLoader);
    this.otpSentSubscription = this.data.crntIsOtpSent.subscribe(isOtpSent => this.isOtpSent = isOtpSent);
    this.otpVerifiedSubscription = this.data.crntIsOtpVerified.subscribe(isOtpVerified => this.isOtpVerified = isOtpVerified);
    // this.authService.getCurrentUser().subscribe(data => this.user = data);
  }

  // handeOtpChange(value: string[]): void {
  //   // console.log(value);
  //   this.otpArray = value;
  // }

  // handleFillEvent(value: string): void {
  //   // console.log(value);
  //   this.otpString = value;
  // }

  onOtpChange(value: string) {
    this.showError = false;
    this.otpString = value;
  }

  ngAfterViewInit(): void {
    this.authService.otpAuthInit();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.otpSentSubscription?.unsubscribe();
    this.otpVerifiedSubscription?.unsubscribe();
    this.countDown?.unsubscribe();
  }

  /**
   * close signin modal
   * @return void
   */
  closeModal() {
    this.authService.closeModal();
    this.data.changeIsOtpSent(false);
  }

  async sendOtp() {
    this.phoneSubmitted = true;
    if (this.phoneForm.invalid) {
      return;
    }
    this.isDisableResend = true;
    this.phoneNumber = getMobileNumber(this.phoneForm.value.countryCode, this.phoneForm.value.phoneNumber);
    this.authService.sendOtp(this.phoneNumber, this.phoneForm.value.countryCode);
    this.displayName = await this.authService.getUserNameByPhone(this.phoneNumber);
    if (this.displayName == '' || this.displayName == 'guest' || this.displayName == undefined) {
      this.welcomeText = 'Welcome to YellowZap!'
    } else {
      const firstName = this.displayName?.split(' ')[0];
      this.welcomeText = 'Welcome back, ' + firstName + ' 👋';
    }
    this.startCountdown();
    if (this.isNumberChanged) {
      this.leadService.changeNumberLead(this.phoneNumber);
    }
    if (!this.isResendAtempted)
      this.leadService.createLead(this.phoneNumber);
    this.isNumberChanged = false;
  }

  verifyOtp() {
    // this.submitted = true;
    // if (this.otpForm.invalid) {
    //   // this.keyCode = 'Invalid OTP';
    //   return;
    // }
    // var otpVal = '';
    // this.otpDigits.forEach(element => {
    //   otpVal = otpVal + this.otpForm.get(element)?.value;
    // });
    // // console.log(otpVal);
    // this.authService.verifyOtp(otpVal);

    // this.submitted = true;
    // if (this.otpArray.filter(Boolean).length == 6) {
    //   this.authService.verifyOtp(this.otpString);
    // } else {
    //   this.submitted = true;
    //   return;
    // }
    if (this.otpString.length === 6) {
      this.authService.verifyOtp(this.otpString, this.isDirectLogin);
    } else {
      this.showError = true;
      this.submitted = true;
      return;
    }
  }

  // keyUpEvent(event: { keyCode: number; which: number; }, index: number) {
  //   // this.keyCode = event.keyCode.toString() + ' : ' + event.which;
  //   let pos = index;
  //   let val = this.otpForm.get(this.otpDigits[pos])?.value;
  //   if (event.keyCode === 8 && event.which === 8) {
  //     pos = index - 1;
  //   } else {
  //     if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
  //       pos = index + 1;
  //     }
  //   }
  //   if (pos > -1 && pos < this.otpDigits.length && (val || event.keyCode === 8)) {
  //     this.rows._results[pos].nativeElement.focus();
  //   }
  // }

  onTimerFinished(e: CountdownEvent) {
    if (e.action == 'done') {
      this.isDisableResend = false;
    }
  }

  resendOtp() {
    this.isOtpResend = true;
    this.ngOnDestroy();
    this.ngOnInit();
    if (!this.isResendAtempted)
      this.authService.otpAuthInit();
    this.isOtpSent = false;
    this.sendOtp();
    this.isResendAtempted = true;
    this.leadService.resendLead();
  }

  changeNumber() {
    if (this.counter > 0) {
      this.toastr.clear();
      this.toastr.info(
        'Please wait ' + this.counter + (this.counter === 1 ? ' second' : ' seconds')
        + ' to change the phone number!', 'Did you know!',
        { positionClass: 'toast-bottom-right' },
      );
      return;
    } else {
      this.isOtpResend = false;
      this.isResendAtempted = false;
      this.ngOnDestroy();
      this.ngOnInit();
      this.authService.otpAuthInit();
      // this.phoneForm.get('phoneNumber')?.setValue('');
      this.isOtpSent = false;
      this.showOtpScreen = false;
      this.isNumberChanged = true;
    }
  }

  startCountdown() {
    this.counter = 30;
    this.showOtpScreen = true;
    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter > 0 && this.isOtpSent && !this.showLoader) {
        --this.counter;
      }
      if (this.counter === 29 && this.isOtpSent) {
        this.toastr.clear();
        this.toastr.info(
          'Please check your phone for the verification code!', 'Did you know!',
          {
            positionClass: 'toast-top-right',
            closeButton: true
          },
        );
      }
      if (this.counter === 0 && this.isOtpSent) {
        this.isDisableResend = false;
        this.toastr.clear();
        // this.toastr.warning(
        //   'Phone code auto retrieval timeout!', '',
        //   { positionClass: 'toast-bottom-right' },
        // );
        this.countDown.unsubscribe();
      }
    });
  }

  getFlagUrl(): string {
    const countryCode = this.phoneForm.value.countryCode;
    switch (countryCode) {
      case '+971':
        return 'assets/flags/ae.svg';
      case '+91':
        return 'assets/flags/in.svg';
      case '+94':
        return 'assets/flags/lk.svg';
      case '+61':
        return 'assets/flags/au.svg';
      default:
        return 'assets/flags/ae.svg';
    }
  }

  openTncPage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/tnc'])
    );
    window.open(url, '_blank');
  }

}
