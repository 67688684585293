import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StaffMember } from 'src/app/models/staffMember.model';
import { CoreService } from 'src/app/services/core.service';
import { DataService } from 'src/app/services/data.service';
import { MytaskService } from 'src/app/services/mytask.service';
import { QuickBookingService } from 'src/app/services/quick-booking.service';

@Component({
  selector: 'app-confirm-booking',
  templateUrl: './confirm-booking.component.html',
  styleUrls: ['./confirm-booking.component.scss']
})
export class ConfirmBookingComponent implements OnInit {

  @Input() data: any;

  taskId?: string;
  email?: string;
  phone?: string;
  isSecondaryUser: boolean = false;
  enableAdminFeatures: boolean = false;
  sharedPaymentLink: string = '';
  isPreferredStaffEnabled: boolean = false;
  selectedStaff: StaffMember[] = [];
  headerStr: String = 'Your Booking Is Placed!';
  staffPicUri?: String;

  constructor(
    private router: Router,
    private quickbookingService: QuickBookingService,
    private dataService: DataService,
    private myTaskService: MytaskService,
    private coreService: CoreService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.setModalData();
    }
  }

  setModalData(): void {
    this.taskId = this.data?.taskId;
    this.email = this.data?.email;
    this.phone = this.data?.phone;
    this.isSecondaryUser = this.data?.isSecondaryUser;
    this.enableAdminFeatures = this.data?.enableAdminFeatures;
    this.sharedPaymentLink = this.data?.sharedPaymentLink;
    this.isPreferredStaffEnabled = this.data?.isPreferredStaffEnabled ?? false;
    this.selectedStaff = this.data?.selectedStaff ?? [];
    this.setStaffInfo();
  }

  setStaffInfo() {
    if (this.isPreferredStaffEnabled && this.selectedStaff?.length > 0) {
      this.headerStr = `Your Booking is Placed with ${this.selectedStaff[0]?.memberName}!`;
      this.staffPicUri = this.selectedStaff[0]?.profileImage;
    } else {
      this.headerStr = 'Your Booking is Placed!';
      this.staffPicUri = undefined;
    }
  }

  async explore(): Promise<void> {
    this.dataService.clearSession();
    this.quickbookingService.closeModal();
    await this.router.navigate(['/home']);
    window.location.reload();
  }

  quickBooking(url: string) {
    this.dataService.clearSession();
    this.quickbookingService.closeModal();
    this.router.navigate([url]);
  }

  navigateToMyTasks() {
    this.myTaskService.changeActiveTaskTab(0);
    this.dataService.clearSession();
    this.quickbookingService.closeModal();
    this.router.navigate(['/my-task']);
  }

  downloadApp(): void {
    const os = this.coreService.getMobileOperatingSystem();
    const androidUrl = "https://play.google.com/store/apps/details?id=com.yellochat.prod&amp;hl=en&amp;gl=US";
    const iosUrl = "https://apps.apple.com/us/app/yellochat/id1513815109";

    if (os === 'android')
      window.open(androidUrl, '_blank');
    else if (os === 'ios')
      window.open(iosUrl, '_blank');
    else
      this.showDownloadSec();
  }

  showDownloadSec() {
    this.dataService.clearSession();
    this.quickbookingService.closeModal();
    this.router.navigate(['/home', 'downloads']);
  }

  copyLink() {
    this.coreService.copyText(this.sharedPaymentLink);
  }

}