<div class="staff-unavailable-modal">

    <div class="modal-body container-fluid">
        <div class="staff-card d-flex align-items-center">
            <span></span>
            <img src="{{staff?.profileImage ?? 'assets/images/user.svg'}}" alt="">
            <div class="txt-col">
                <h4>{{staff?.memberName}}</h4>
                <p>AED 32/hr</p>
            </div>
        </div>

        <div class="error-txt d-flex">
            <img src="assets/images/icons/info-circle-red.svg" alt="">
            {{staff?.memberName + " is not available" + dateTimeStr}}
        </div>

        <div class="options">
            <div *ngFor="let option of optionList" class="tile d-flex align-items-center"
                [ngClass]="{'tile__selected': option.id === selectedOption?.id}" (click)="select(option)">
                <div class="radio-btn d-flex align-items-center justify-content-center"><span></span></div>
                <p>{{option?.title}}</p>
            </div>
        </div>

    </div>

    <div class="modal-footer d-flex align-items-center justify-content-center">
        <button type="submit" (click)="confirm()">Continue</button>
    </div>
</div>