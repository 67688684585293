import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StaffMember } from 'src/app/models/staffMember.model';

interface Option {
  id: number;
  title: string;
  action: string;
}

@Component({
  selector: 'app-staff-unavailable',
  templateUrl: './staff-unavailable.component.html',
  styleUrls: ['./staff-unavailable.component.scss']
})
export class StaffUnavailableComponent implements OnInit {

  optionList: Option[] = [
    { id: 1, title: "Auto assign a Zapper maid for me", action: "AUTO" },
    { id: 2, title: "Select another available Zapper maid", action: "STAFF" },
    { id: 3, title: "Choose another available time", action: "TIME" }
  ];
  selectedOption?: Option;
  staff?: StaffMember;
  dateTimeStr: string = '';

  @Input() staffMembers?: StaffMember[];
  @Input() jobDateTime?: Date;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.staffMembers && this.staffMembers?.length > 0) {
      this.staff = this.staffMembers[0];
    }
    this.setJobDateTime(this.jobDateTime);
    this.select(this.optionList[0]);
  }

  setJobDateTime(dateTime?: Date) {
    if (!dateTime) {
      this.dateTimeStr = ' at selected time';
      return;
    }
    const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' };
    const timeStr = new Intl.DateTimeFormat('en-US', timeOptions).format(dateTime);
    const dateStr = new Intl.DateTimeFormat('en-US', dateOptions).format(dateTime);
    this.dateTimeStr = ` at ${timeStr} on ${dateStr}`;
  }

  select(option: Option) {
    this.selectedOption = option;
  }

  confirm() {
    if (!this.selectedOption) {
      return;
    }
    this.passBack(this.selectedOption);
  }

  passBack(data: any) {
    this.passEntry.emit(data);
    this.activeModal.close(data);
  }

}
