import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.scss']
})
export class BottomNavbarComponent implements OnInit, OnDestroy {

  activeIndex: number = 0;
  routeSub?: Subscription;
  showNavBar: boolean = true;
  validUrls: string[] = [
    '/',
    '/home',
    '/dubai',
    '/dubai/services',
    '/my-task',
    '/more',
    '/more/help-and-support'
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.routeSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const crntTabIdx = this.getCrntTabIndex(event.url);
        this.setActiveTab(crntTabIdx);
        this.showNavBar = this.validUrls.includes(event.url) ? true : false;
      }
    });
  }

  ngOnDestroy(): void {
    this.routeSub?.unsubscribe();
  }

  setActiveTab(index: number) {
    this.activeIndex = index;
  }

  getCrntTabIndex(routeUrl: string) {
    switch (routeUrl) {
      case '/':
        return 0;
      case '/home':
        return 0;
      case '/dubai':
        return 0;
      case '/dubai/services':
        return 1;
      case '/my-task':
        return 2;
      case '/more':
        return 3;
      case '/more/help-and-support':
        return 3;
      default:
        return 0;
    }
  }

  navigateToPage(index: number) {
    this.setActiveTab(index);
    if (index === 0)
      this.router.navigate(['/dubai']);
    if (index === 1)
      this.router.navigate(['/dubai/services']);
    if (index === 2)
      this.router.navigate(['/my-task']);
    if (index === 3)
      this.router.navigate(['/more']);
  }

}
