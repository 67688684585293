import { Address } from "src/app/models/address.model"

export function getSelectedLocation(currentAddress?: Address) {
  if (currentAddress?.location) return currentAddress.location
  else if (currentAddress?.landmarkKey)
    return `${(!currentAddress?.landmarkKey ? '' : `${currentAddress?.landmarkKey}`)}
        ${(!currentAddress?.flatNo ? '' : `, ${currentAddress?.flatNo}`)}
        ${(!currentAddress?.flatName ? '' : `, ${currentAddress?.flatName}`)}
      ${(!currentAddress?.landmark ? '' : `, ${currentAddress?.landmark}`)}`
  else
    return ''
}


export function getEnquiryLocation(currentAddress?: Address) {
  if (currentAddress?.location) return currentAddress.location
  else if (currentAddress?.landmarkKey)
    return `${(!currentAddress?.landmarkKey ? '' : `${currentAddress?.landmarkKey}`)}
        ${(!currentAddress?.flatNo ? '' : `, ${currentAddress?.flatNo}`)}
        ${(!currentAddress?.flatName ? '' : `, ${currentAddress?.flatName}`)}
      ${(!currentAddress?.landmark ? '' : `, ${currentAddress?.landmark}`)}`
  else
    return 'Location not set'
}
