export interface ICategory {
    docId: string;
    categoryName: string;
    displayOrder: number;
    focusGroupDisplayOrder: number;
    image: string;
    imageNew: string;
    isActive: boolean;
    isFocusGroup: boolean;
}

export class Category {
    docId: string;
    categoryName: string;
    displayOrder: number;
    focusGroupDisplayOrder: number;
    image: string;
    imageNew: string;
    isActive: boolean;
    isFocusGroup: boolean;
    constructor(data: ICategory) {
        this.docId = data.docId;
        this.categoryName = data.categoryName;
        this.displayOrder = data.displayOrder;
        this.focusGroupDisplayOrder = data.focusGroupDisplayOrder;
        this.image = data.image;
        this.imageNew = data.imageNew;
        this.isActive = data.isActive;
        this.isFocusGroup = data.isFocusGroup;
    }
}