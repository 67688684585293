<div class="price-modal">
    <div class="modal-header text-center">
        <h5 class="modal-title w-100">Price Breakdown</h5>
    </div>

    <div class="modal-body container-fluid" *ngIf="priceBreakdownList">
        <table class="table table-borderless">
            <tbody>
                <tr *ngFor="let row of priceBreakdownList" class="pb-0">
                    <td><p style="padding-bottom: 0;">{{row?.title}}</p></td>
                    <td class="text-end"><p style="font-weight: 400; padding-bottom: 0;">{{row?.value}}</p></td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="modal-footer">
        <button type="button" class="yc-btn" (click)="closeModal()">Close</button>
    </div>
</div>