<div class="download-banner" [ngClass]="{'banner-active': showBanner}">
    <div class="banner-inner">
        <div class="item-row d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
                <div class="close-sec" (click)="closeBanner()">
                    <svg width="30" height="30" viewBox="0 0 30 30">
                        <circle cx="15" cy="15" r="14" fill="none" stroke="#D9D9D980" stroke-width="2" />
                        <circle class="percent" id="progress-percent" cx="15" cy="15" r="14" fill="none"
                            stroke="#FFFFFF" stroke-width="2" pathLength="10000" />
                        <image x="50%" y="50%" xlink:href="/assets/images/icons/close-white.webp" height="12" width="12"
                            transform="translate(-6, -6)" />
                    </svg>
                </div>
                <img class="logo" src="/assets/images/logo-yellowzap.webp" alt="">
                <div class="txt-col">
                    <h4 class="title">Download YellowZap app !</h4>
                    <h4 class="subtitle">Enjoy app exclusive deals</h4>
                </div>
            </div>
            <button class="btn" (click)="downloadApp()">Install Now</button>
        </div>
    </div>
</div>