<app-download-banner></app-download-banner>
<app-notification-wrapper></app-notification-wrapper>
<app-header *ngIf="showHeader"></app-header>

<main>
    <app-snackbar id="yc-snackbar"></app-snackbar>
    <!-- Spinner for lazyload modules -->
    <!-- <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
    </div> -->

    <div contentAnimate>
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
    <!-- <app-phone-auth></app-phone-auth> -->
</main>

<div id="footer-wrap">
    <app-footer *ngIf="footerVisible"></app-footer>
</div>

<app-bottom-navbar></app-bottom-navbar>
<div id="recaptcha-container" class="d-none"></div>