import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AvailableDiscounts } from 'src/app/models/availableDiscounts';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { QuickBookingService } from 'src/app/services/quick-booking.service';

@Component({
  selector: 'app-available-promos',
  templateUrl: './available-promos.component.html',
  styleUrls: ['./available-promos.component.scss']
})
export class AvailablePromosComponent implements OnInit {

  discounts: any[] = [];
  activeDetailList: number[] = [];
  isSnackBarActive: boolean = false;
  voucherCode: string = '';
  showLoader: boolean = false;
  localUid: string = '';
  snackBarTxt: string = '';
  showActivationWin: boolean = false;
  isSuperVoucherActive: boolean = false;
  errorTxt: string = '';
  showRefActivate: boolean = false;
  refActivateData: any;
  showSnackbarIcon: boolean = true;
  coppiedPromo: String = '';

  @Input() availableDiscountRes!: AvailableDiscounts;
  @Input() showApplyBtn: boolean = false;
  @Input() showActivation: boolean = false;
  @Input() superVoucherData: any;
  @Input() voucherConfigData: any;
  @Input() docId?: string;
  @Input() isShowOnly: boolean = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private dataService: DataService,
    private authService: AuthService,
    private quickbookingService: QuickBookingService,
    private toastr: ToastrService,
    private clipboard: Clipboard) { }

  ngOnInit(): void {
    if (this.availableDiscountRes) {
      this.discounts = this.availableDiscountRes?.discounts;
      this.processDiscounts(this.discounts);
      this.showActivationWin = this.showActivation;
      this.setRefActivateData(this.availableDiscountRes);
    }
    this.localUid = localStorage.getItem('uid') ?? '';
    if (this.superVoucherData?.activate) {
      this.isSuperVoucherActive = true;
    }
    this.coppiedPromo = this.dataService.getCopiedPromo();
  }

  passBack(discount: any) {
    this.passEntry.emit(discount);
    this.activeModal.close(discount);
  }

  close() {
    this.activeModal.close();
  }

  toggleDetailView(i: number) {
    if (this.activeDetailList.includes(i))
      this.activeDetailList = this.activeDetailList.filter(e => e !== i);
    else
      this.activeDetailList.push(i);
  }

  showDetails(i: number): boolean {
    return this.activeDetailList.includes(i);
  }

  applyPromo(discount: any) {
    this.passBack(discount);
  }

  showSnackBar() {
    if (this.isSnackBarActive && !this.snackBarTxt) return;
    var ele = document.getElementById("snackbar");

    if (ele) {
      ele.className = "show";
      this.isSnackBarActive = true;
    }

    setTimeout(() => {
      if (ele)
        ele.className = ele.className.replace("show", "hide");
      this.isSnackBarActive = false;
    }, 2998);
  }

  hideSnackBar() {
    if (!this.isSnackBarActive) return;
    const ele = document.getElementById("snackbar");
    if (ele) {
      ele.className = ele.className.replace("show", "hide");
      this.isSnackBarActive = false;
    }
  }

  activateVoucher() {
    this.showLoader = true;
    this.errorTxt = '';
    const req = {
      "superVoucher": true,
      "code": this.voucherCode ?? '',
      "userId": this.localUid ?? '',
      "apiVersion": 1.2
    }

    this.apiService.getDiscount(req).subscribe(
      async res => {
        this.setDiscounts(res);
        this.errorTxt = '';
        this.showLoader = false;
      },
      error => {
        this.showLoader = false;
        if (error?.error?.message) {
          this.errorTxt = error?.error?.message ?? '';
        }
        else {
          this.toastr.clear();
          this.toastr.error(
            'Oops! Something went wrong.', 'Something went wrong!',
            { positionClass: 'toast-bottom-right' },
          );
          throw error;
        }
      }
    )
  }

  setDiscounts(res: any) {
    if (res?.status === 'success') {
      this.snackBarTxt = res.superVoucherDetails?.messageDetails?.success ?? '';
      this.discounts = res.discounts;
      this.processDiscounts(this.discounts);
      this.showSnackbarIcon = true;
      this.showSnackBar();
      this.dataService.changeSuperVoucher(res);
      if (this.docId) {
        this.quickbookingService.getAvailableDiscounts(this.docId, true);
      }
      this.isSuperVoucherActive = res.superVoucherDetails?.activate ? true : false;
      this.showActivation = false;
    }
  }

  showVoucherActivation() {
    const isLoggedIn = this.dataService.getIsLoggedIn();
    if (!isLoggedIn) {
      this.close();
      sessionStorage.setItem('isSessionActive', 'TRUE');
      if (this.docId)
        sessionStorage.setItem('sessionDocId', this.docId);
      this.authService.openModal();
      return;
    }
    this.showActivation = true;
  }

  setRefActivateData(data: any) {
    const isLoggedIn = this.dataService.getIsLoggedIn();
    this.refActivateData = data?.refActivateDetails;
    this.showRefActivate = (isLoggedIn && data?.activateReferral) ? true : false;
  }

  activateReferral() {
    const reopenAvailablePromo = this.showApplyBtn ?? false;
    this.close();
    this.quickbookingService.openActivateReferralModal(this.availableDiscountRes?.refActivateDetails, reopenAvailablePromo, this.docId);
  }

  applicableClick() {
    this.showSnackbarIcon = false;
    this.snackBarTxt = 'Promotion can be applied at checkout';
    this.showSnackBar();
  }

  copyPromoCode(discount: any) {
    const code = discount?.data?.code ?? discount?.code;
    this.clipboard.copy(code);
    this.coppiedPromo = code;
    this.dataService.changeCopiedPromo(code);
    this.showSnackbarIcon = false;
    this.snackBarTxt = `"${code}" copied to your clipboard.`;
    this.showSnackBar();
  }

  processDiscounts(discounts: any[]) {
    if (!this.isShowOnly || !(discounts.length > 0)) return;
    const updatedDiscountList = discounts.filter((discount) => !discount.data.discount.superVoucher && !discount.data.discount.subSuperVoucher);
    this.discounts = updatedDiscountList;
  }

}
