import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationStart,
  Resolve,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { SpinnerOverlayService } from '../services/spinner-overlay.service';

@Injectable({
  providedIn: 'root'
})
export class UserResolver implements Resolve<User> {
  constructor(private authService: AuthService, private spinnerOverlayService: SpinnerOverlayService) {
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User> {
    this.checkAndShowSpinner(route?.routeConfig?.path ?? "");
    const localUid = localStorage.getItem('uid') ?? '';
    return this.authService.getUserData(localUid) as User | any;
  }

  private checkAndShowSpinner(path: string) {
    const paths: string[] = ['payment/:status/:bookingId'];
    if (paths.includes(path)) {
      this.spinnerOverlayService.openLoadingModal();
    }
  }
}
