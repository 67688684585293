import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category, ICategory } from '../models/category.model';
import { ISubCategory, SubCategory } from '../models/subCategory.model';
import { LandingBannerComponent } from '../shared/components/landing-banner/landing-banner.component';
import { IServiceConfig, ServiceConfig } from '../models/serviceConfig.mode';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  subscriptions: Subscription[] = [];
  subCategories: SubCategory[] = [];
  allSubCategories: SubCategory[] = [];
  menuItems: Category[] = [];
  private $subCategories: BehaviorSubject<Array<SubCategory>> = new BehaviorSubject(this.subCategories);
  private $allSubCategories: BehaviorSubject<Array<SubCategory>> = new BehaviorSubject(this.allSubCategories);
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  lastCategoryDoc?: QueryDocumentSnapshot<DocumentData>;
  private $menuItems: BehaviorSubject<Array<Category>> = new BehaviorSubject(
    this.menuItems
  );
  isLandingPopUpOpen: boolean = false;


  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public modalService: NgbModal
  ) {
    this.getMenuItemsSubscription();
    this.getSubCategoriesInit();
  }

  getSubCategoriesInit() {
    this.subCategories = [];
    this.$subCategories.next(this.subCategories);
    const blockedCategories = environment?.production ? [7, 2, 15, 21] : [7, 2, 21];
    const sub = this.afs.collection('subcategories', (ref) =>
      ref
        .where('isActive', '==', true)
        .where('newType', 'not-in', blockedCategories)
        .orderBy('newType', 'asc')
        .orderBy('displayOrder', 'asc')
    )
      .snapshotChanges()
      .subscribe((data: any) =>
        this.updateArray(data, true)
      )
  }

  updateArray(
    data: DocumentChangeAction<DocumentData>[],
    past: boolean
  ) {
    if (data.length === 0) {
      return;
    }
    if (past) {
      this.lastDoc = data.slice(-1).pop()?.payload.doc;
    }
    const _subcategory = data.map((a) => new SubCategory(a.payload.doc.data() as ISubCategory));
    const _docIds = data.map((a) => a.payload.doc.id);
    this.allSubCategories = [];

    _subcategory.forEach((_incomingReplacement, index) => {
      _incomingReplacement.id = _docIds[index];
      const replacementIds = this.subCategories.map((subCat) => subCat.id);
      const indexOfIncomingReplacement = replacementIds.indexOf(
        _incomingReplacement.id
      );
      this.allSubCategories.push(_incomingReplacement);
      if (indexOfIncomingReplacement >= 0) {
        this.subCategories[indexOfIncomingReplacement] = _incomingReplacement;
      } else {
        if (past) {
          this.subCategories.push(_incomingReplacement);
        } else {
          this.subCategories.unshift(_incomingReplacement);
        }
      }
    });

    this.$allSubCategories.next(this.allSubCategories);
    this.$subCategories.next(this.subCategories);
    if (environment.showLandingPopup && this.router.url === '/home')
      this.showLandingPopUp();
  }

  getSubscription() {
    return this.$subCategories.asObservable();
  }

  getAllSubCatSubscription() {
    return this.$allSubCategories.asObservable();
  }

  getMenuItemsInit() {
    this.menuItems = [];
    this.$menuItems.next(this.menuItems);
    const sub = this.afs.collection('categories', (ref) =>
      ref
        .where('isActive', '==', true)
        .orderBy('displayOrder', 'asc')
    )
      .snapshotChanges()
      .subscribe((data: any) =>
        this.updateMenuItemArray(data, true)
      )
  }

  updateMenuItemArray(
    data: DocumentChangeAction<DocumentData>[],
    past: boolean
  ) {
    if (data.length === 0) {
      return;
    }
    if (past) {
      this.lastCategoryDoc = data.slice(-1).pop()?.payload.doc;

    }
    const _category = data.map((a) => new Category(a.payload.doc.data() as ICategory));
    const _docIds = data.map((a) => a.payload.doc.id);

    _category.forEach((_incomingCategory, index) => {
      _incomingCategory.docId = _docIds[index];
      const categoryIds = this.menuItems.map((category) => category.categoryName);
      const indexOfIncomingCategory = categoryIds.indexOf(
        _incomingCategory.categoryName
      );
      if (indexOfIncomingCategory >= 0) {
        this.menuItems[indexOfIncomingCategory] = _incomingCategory;
      } else {
        if (past) {
          this.menuItems.push(_incomingCategory);
        } else {
          this.menuItems.unshift(_incomingCategory);
        }
      }
    });
    this.$menuItems.next(this.menuItems);
  }

  getMenuItemsSubscription() {
    return this.$menuItems.asObservable();
  }

  showLandingPopUp() {
    for (let i = 0; i < (this.subCategories?.length ?? 0); i++) {
      if (this.subCategories[i].inAppBanner?.isActive && !this.isLandingPopUpOpen) {
        this.openLandingBannerModal(this.subCategories[i].inAppBanner, this.subCategories[i].id);
        this.isLandingPopUpOpen = true;
        break;
      }
    }
  }

  openLandingBannerModal(data: any, categoryId: string) {
    const modalRef = this.modalService.open(LandingBannerComponent, {
      backdrop: 'static',
      // centered: true,
      keyboard: false,
      modalDialogClass: 'full-width'
    });
    modalRef.componentInstance.bannerTitle = data?.title;
    modalRef.componentInstance.bannerImage = data?.images[0];
    modalRef.componentInstance.bannerContent = data?.content;
    modalRef.componentInstance.categoryId = categoryId;
  }

  public async getServiceConfig(serviceId?: string, serviceConfig?: IServiceConfig) {
    return await this.getServiceConfigByService(serviceId, serviceConfig);
  }

  private async getServiceConfigByService(serviceId?: string, serviceConfig?: IServiceConfig | any) {
    if (serviceId === undefined) return null;
    if (serviceConfig?.serviceId === serviceId) {
      return serviceConfig;
    } else {
      const res = await this.afs.collection('serviceConfig').doc(serviceId).get().toPromise();
      serviceConfig = res.data() as IServiceConfig;
      return serviceConfig;
    }
  }

}
