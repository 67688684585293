import { SubCategory } from "./subCategory.model";

export interface IPromotion {
    adminUid: string;
    createdAt: string;
    description: string;
    endTimestamp: firebase.default.firestore.Timestamp;
    image: string;
    isActive: boolean;
    isNotificationSent: boolean;
    isPostedByAdmin: true;
    location: {
        locality: string;
        subLocality: string;
    }
    numOfReviews: number;
    rating: number;
    subCategory: {
        id: string;
        name: string;
        isQuickBooking: boolean;
    }
    timestamp: firebase.default.firestore.Timestamp;
    timestampValidFrom: firebase.default.firestore.Timestamp;
    timestampValidTo: firebase.default.firestore.Timestamp;
    validfrom: string;
    validto: string;
    vendorName: string;
    vendoruid: string;
    showReadMore: boolean;
    noPromotions: boolean;
    subCategoryDetails: SubCategory;
    imageNew: string;
    imageName: string;
    isExploreType: boolean;
    exploreOrderBy: any;
}

export class Promotion {
    adminUid: string;
    docId: string;
    createdAt: string;
    description: string;
    endTimestamp: firebase.default.firestore.Timestamp;
    image: string;
    isActive: boolean;
    isNotificationSent: boolean;
    isPostedByAdmin: true;
    location: {
        locality: string;
        subLocality: string;
    }
    numOfReviews: number;
    rating: number;
    subCategory: {
        id: string;
        name: string;
        isQuickBooking: boolean;
    }
    timestamp: firebase.default.firestore.Timestamp;
    timestampValidFrom: firebase.default.firestore.Timestamp;
    timestampValidTo: firebase.default.firestore.Timestamp;
    validfrom: string;
    validto: string;
    vendorName: string;
    vendoruid: string;
    showReadMore: boolean;
    noPromotions: boolean;
    subCategoryDetails: SubCategory;
    imageNew: string;
    imageName: string;
    isExploreType: boolean;
    exploreOrderBy: any;

    constructor(data: IPromotion, docId: string) {
        this.adminUid = data.adminUid;
        this.docId = docId
        this.createdAt = data.createdAt;
        this.description = data.description;
        this.endTimestamp = data.endTimestamp;
        this.image = data.image;
        this.isActive = data.isActive;
        this.isNotificationSent = data.isNotificationSent;
        this.isPostedByAdmin = data.isPostedByAdmin;
        this.location = data.location;
        this.numOfReviews = data.numOfReviews;
        this.rating = data.rating;
        this.subCategory = data.subCategory;
        this.timestamp = data.timestamp;
        this.timestampValidFrom = data.timestampValidFrom;
        this.timestampValidTo = data.timestampValidTo;
        this.validfrom = data.validfrom;
        this.validto = data.validto;
        this.vendorName = data.vendorName;
        this.vendoruid = data.vendoruid;
        this.showReadMore = data.showReadMore;
        this.noPromotions = data.noPromotions;
        this.subCategoryDetails = data.subCategoryDetails;
        this.imageNew = data.imageNew;
        this.imageName = data.imageName;
        this.isExploreType = data.isExploreType;
        this.exploreOrderBy = data.exploreOrderBy;
    }
}
