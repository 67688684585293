import { Bookings } from "src/app/models/bookings.model";
import { Requirement } from "src/app/models/requirement.model";
import firebase from 'firebase/app';
import Timestamp = firebase.firestore.Timestamp;

let cashList = [
  'Booking Placed',
  'Job Confirmed',
  'Staff Assigned',
  'Vendor on the way',
  'Staff Near Your Location',
  'Job In Progress',
  'Job Completed',
  'Payment Completed'
]

let creditList = [
  'Booking Placed',
  'Payment Processed',
  'Job Confirmed',
  'Staff Assigned',
  'Vendor on the way',
  'Staff Near Your Location',
  'Job In Progress',
  'Job Completed',
  'Payment Completed'
]

export function isStatusComplete(value: string, bookingModel: Bookings) {
  if (bookingModel?.currentStatus == null) return false
  let isCash = bookingModel?.paymentMethod?.id == 'cash'
  if (isCash) {
    switch (value) {
      case 'Vendor Assigned':
      case 'Staff Assigned':
        return (bookingModel?.newStatus?.['1']?.status ?? false);
      case 'Job Confirmed':
        return (bookingModel?.newStatus?.['2']?.status ?? false);
      case 'Vendor on the way':
        return (bookingModel?.newStatus?.['3']?.status ?? false);
      case 'Job In Progress':
        return (bookingModel?.newStatus?.['4']?.status ?? false);
      case 'Job Completed':
        return (bookingModel?.newStatus?.['5']?.status ?? false);
      case 'Payment Completed':
        return (bookingModel?.newStatus?.['6']?.status ?? false);
      case 'Staff Near Your Location':
        return (bookingModel?.vendorStatusData?.four?.status ?? false) || (bookingModel?.newStatus?.four?.status ?? false) || (bookingModel?.newStatus?.['5']?.status ?? false);//Remove the second condition if not required
      default:
        return (bookingModel?.newStatus?.['0']?.status ?? false);
    }
  } else {
    switch (value) {
      case 'Payment Processed':
        return (bookingModel?.newStatus?.['1']?.status ?? false);
      case 'Vendor Assigned':
      case 'Staff Assigned':
        return (bookingModel?.newStatus?.['2']?.status ?? false);
      case 'Job Confirmed':
        return (bookingModel?.newStatus?.['3']?.status ?? false);
      case 'Vendor on the way':
        return (bookingModel?.newStatus?.['4']?.status ?? false);
      case 'Staff Near Your Location':
        return (bookingModel?.vendorStatusData?.four?.status ?? false) || (bookingModel?.newStatus?.['5']?.status ?? false);//Remove the second condition if not required
      case 'Job In Progress':
        return (bookingModel?.newStatus?.['5']?.status ?? false);
      case 'Job Completed':
        return (bookingModel?.newStatus?.['6']?.status ?? false);
      case 'Payment Completed':
        return (bookingModel?.newStatus?.['7']?.status ?? false) || (bookingModel?.newStatus?.['6']?.status ?? false);//Remove the second condition if not required
      default:
        return (bookingModel?.newStatus?.['0']?.status ?? false);
    }
  }
}

function getListWithSort(model: Bookings) {
  let data: any = []
  if (model?.paymentMethod?.id == 'credit') {
    if (model?.newStatus?.['0']?.startTime != null) {
      data.push({ 'time': model.newStatus['0'].startTime, 'value': 'Booking Placed' });
    }
    if (model?.newStatus?.['1']?.startTime != null) {
      data.push({ 'time': model.newStatus['1'].startTime, 'value': 'Payment Processed' });
    }
    if (model?.newStatus?.['3']?.startTime != null) {
      data.push({ 'time': model.newStatus['3'].startTime, 'value': model.newStatus?.['3']?.subStatus == 0 ? 'Job Cancelled' : 'Job Confirmed' });
    }
    if (model?.newStatus?.['2']?.startTime != null) {
      data.push({ 'time': model.newStatus['2'].startTime, 'value': 'Zapper Assigned' });
    }
    if (model?.newStatus?.['4']?.startTime != null) {
      data.push({ 'time': model.newStatus['4'].startTime, 'value': 'Vendor on the way' });
    }
    if (model?.newStatus?.['5']?.startTime != null) {
      data.push({ 'time': model.newStatus['5'].startTime, 'value': 'Job In Progress' });
    }
    if (model?.newStatus?.['6']?.startTime != null) {
      data.push({ 'time': model.newStatus['6'].startTime, 'value': 'Job Completed' });
    }
    if (model?.newStatus?.['7']?.startTime != null) {
      data.push({ 'time': model.newStatus['7'].startTime, 'value': 'Payment Completed' });
    }
    if (model?.newStatus?.['4']?.startTime != null || model?.newStatus?.['5']?.startTime != null) {
      var date = firebase.firestore.Timestamp.fromDate(new Date(getDateFromTimestamp(model.newStatus['4'].startTime).getTime() + 1000));
      if (model?.newStatus?.['5']?.startTime != null) {
        date = firebase.firestore.Timestamp.fromDate(new Date(getDateFromTimestamp(model.newStatus['5'].startTime).getTime() - 1000));
      }
      data.push({ 'time': date, 'value': 'Staff Near Your Location' });
    }
  } else {
    // cash
    if (model?.newStatus?.['0']?.startTime != null) {
      data.push({ 'time': model.newStatus['0'].startTime, 'value': 'Booking Placed' });
    }
    if (model?.newStatus?.['2']?.startTime != null) {
      data.push({ 'time': model.newStatus['2'].startTime, 'value': model.newStatus?.['2']?.subStatus == 0 ? 'Job Cancelled' : 'Job Confirmed' });
    }
    if (model?.newStatus?.['1']?.startTime != null) {
      data.push({ 'time': model.newStatus['1'].startTime, 'value': 'Zapper Assigned' });
    }
    if (model?.newStatus?.['3']?.startTime != null) {
      data.push({ 'time': model.newStatus['3'].startTime, 'value': 'Vendor on the way' });
    }
    if (model?.newStatus?.['4']?.startTime != null) {
      data.push({ 'time': model.newStatus['4'].startTime, 'value': 'Job In Progress' });
    }
    if (model?.newStatus?.['5']?.startTime != null) {
      data.push({ 'time': model.newStatus['5'].startTime, 'value': 'Job Completed' });
    }
    if (model?.newStatus?.['6']?.startTime != null) {
      data.push({ 'time': model.newStatus['6'].startTime, 'value': 'Payment Completed' });
    }
    if (model?.newStatus?.['3']?.startTime != null || model?.newStatus?.['4']?.startTime != null) {
      var date = firebase.firestore.Timestamp.fromDate(new Date(getDateFromTimestamp(model.newStatus['3'].startTime).getTime() + 1000));
      if (model?.newStatus?.four?.startTime != null) {
        date = firebase.firestore.Timestamp.fromDate(new Date(getDateFromTimestamp(model.newStatus['4'].startTime).getTime() - 1000));
      }
      data.push({ 'time': date, 'value': 'Staff Near Your Location' });
    }
  }
  data?.sort((a: any, b: any) => a.hasOwnProperty('time') && b.hasOwnProperty('time') ? a['time'] > b['time'] : 0);
  return data;
}

export function getStatusList(model: Bookings) {
  let sortList = getListWithSort(model)

  let result: any = []

  let typeList = model?.paymentMethod?.id == 'cash' ? cashList : creditList

  // for (let j = 0; j < sortList.length; j++) {
  //   result.push(sortList[j]['value']);
  // }
  for (let i = 0; i < typeList.length; i++) {
    if (!result.includes(typeList[i])) {
      result.push(typeList[i]);
    }
  }
  return result;
}

export function getMainStatusText(model?: Requirement) {


  // if (model?.docId === '235') {
  //   console.log(model)
  // }
  let status = `${model?.status ?? ''}`.toLowerCase();
  if (status == 'closed') return 'CLOSED';
  if (model?.bookingModel != null) return getStatusText(model.bookingModel, model?.ratingSummary);
  if (model?.subCategory?.type === 12) return getStatusText(model as unknown as Bookings);
  if (model?.currentStatus != null && model?.newStatus != null) {
    // new custom booking statuses
    if (model.currentStatus == 0)
      return 'TASK CREATED';
    else if (model.currentStatus == 1)
      return 'TASK POSTED';
    else if (model.currentStatus == 9) //2
      return 'TASK CLOSED';
    else if (model.currentStatus == 3)
      return 'BOOKING CONFIRMED';
    else if (model.currentStatus == 4)
      if (model?.newStatus?.['4']?.subStatus == 0)
        return 'JOB CANCELLED';
      else
        return 'JOB CONFIRMED';
    else if (model.currentStatus == 5)
      return 'VENDOR ON THE WAY';
    else if (model.currentStatus == 6)
      return 'JOB IN PROGRESS';
    else if (model.currentStatus == 7)
      return 'JOB COMPLETED';
    else if (model.currentStatus == 8)
      return 'TASK CANCELLED';
    else
      return 'BOOKING PLACED';
  } else {
    if (status == 'notinterest')
      return 'NO INTEREST';
    else
      return 'ACTIVE';
  }
}

export function getStatusText(model: Bookings, ratingSummary?: any) {
  if (model?.paymentMethod?.id == 'cash') {
    if (model.currentStatus == 6)
      // return 'PAYMENT COMPLETED';
      if (model?.newStatus?.['6']?.subStatus == 0)
        return 'PAYMENT CANCELLED';
      else if (model?.newStatus?.['6']?.subStatus == 1)
        return 'REFUND INITIATED';
      else if (model.jobStatus === 'Job Completed' && !ratingSummary && model?.staffDetails?.memberCount > 0) {
        return 'REVIEW PENDING';
      } else if (model.jobStatus === 'Job Completed' && ratingSummary && model?.staffDetails?.memberCount > 0) {
        return 'REVIEW COMPLETED'
      } else
        return 'PAYMENT COMPLETED';
    else if (model.currentStatus == 1)
      return 'ZAPPER ASSIGNED';
    else if (model.currentStatus == 2)
      // return 'JOB CONFIRMED';
      if (model?.newStatus?.['2']?.subStatus == 0)
        return 'JOB CANCELLED';
      else
        return 'JOB CONFIRMED';
    else if (model.currentStatus == 3 && model.vendorCurrentStatus != 4)
      return 'VENDOR ON THE WAY';
    else if (model.vendorCurrentStatus == 4 && model.currentStatus == 3)
      return 'STAFF NEAR YOUR LOCATION';
    else if (model.currentStatus == 4)
      return 'JOB IN PROGRESS';
    else if (model.currentStatus == 5) {
      if (model.jobStatus === 'Job Completed' && !ratingSummary && model?.staffDetails?.memberCount > 0) {
        return 'REVIEW PENDING';
      } else if (model.jobStatus === 'Job Completed' && ratingSummary && model?.staffDetails?.memberCount > 0) {
        return 'REVIEW COMPLETED'
      }
      else {
        return 'JOB COMPLETED';
      }
    }
    // else if (model.currentStatus == 5)
    //   return 'JOB COMPLETED';
    else
      return 'BOOKING PLACED';
  } else {
    if (model.currentStatus == 7)
      // return 'PAYMENT COMPLETED';
      if (model?.newStatus?.['7']?.subStatus == 0)
        return 'PAYMENT CANCELLED';
      else if (model?.newStatus?.['7']?.subStatus == 1)
        return 'REFUND INITIATED';
      else
        return 'PAYMENT COMPLETED';
    else if (model.currentStatus == 1)
      return 'PAYMENT PROCESSED';
    else if (model.currentStatus == 2)
      return 'ZAPPER ASSIGNED';
    else if (model.currentStatus == 3)
      // return 'JOB CONFIRMED';
      if (model?.newStatus?.['3']?.subStatus == 0)
        return 'JOB CANCELLED';
      else
        return 'JOB CONFIRMED';
    else if (model.currentStatus == 4 && model.vendorCurrentStatus != 4)
      return 'VENDOR ON THE WAY';
    else if (model.vendorCurrentStatus == 4 && model.currentStatus == 4)
      return 'STAFF NEAR YOUR LOCATION';
    else if (model.currentStatus == 5)
      return 'JOB IN PROGRESS';
    else if (model.currentStatus == 6) {
      if (model.jobStatus === 'Job Completed' && !ratingSummary && model?.staffDetails?.memberCount > 0) {
        return 'REVIEW PENDING';
      } else if (model.jobStatus === 'Job Completed' && ratingSummary && model?.staffDetails?.memberCount > 0) {
        return 'REVIEW COMPLETED'
      }
      else {
        return 'JOB COMPLETED';
      }
    }
    else
      return 'BOOKING PLACED';
  }
}

export function statusColor(model?: Requirement) {
  if (model?.jobStatus === 'Job Completed' && !model?.ratingSummary && model?.staffDetails?.memberCount > 0) {
    return 'RED';
  }
  if (model?.paymentMethod?.id === 'cash') {
    if (model?.newStatus?.['6']?.subStatus == 0)
      return 'RED';
    else if (model?.newStatus?.['2']?.subStatus === 0)
      return 'RED';
  } else {
    if (model?.newStatus?.['7']?.subStatus === 0)
      return 'RED';
    else if (model?.newStatus?.['3']?.subStatus === 0)
      return 'RED';
  }
  if (model?.jobStatus === 'Job Cancelled') return 'RED';
  const status = (model?.status ?? '').toLowerCase();
  if (status == 'closed')
    return 'RED';
  else if (status == 'interest')
    return 'GREEN';
  else if (status == 'message')
    return 'THEME';
  else
    return 'GREEN';
}

function getDateFromTimestamp(timestamp: any): Date {
  return new Timestamp(timestamp.seconds ?? 0, timestamp.nanoseconds ?? 0).toDate();
}

export function getAlternateJobStatus(status: string) {
  switch (status) {
    case 'Booking Placed':
      return 'Booking Placed';
    case 'Job Confirmed':
      return 'Job Confirmed';
    case 'Staff Assigned':
      return 'Zapper Assigned';
    case 'Vendor on the way':
      return 'On the Way';
    case 'Staff Near Your Location':
      return 'Near your Location';
    case 'Job In Progress':
      return 'Job Started';
    case 'Job Completed':
      return 'Job Completed';
    case 'Payment Completed':
      return 'Payment Completed';
    default:
      return 'Payment Processed';
  }
}
