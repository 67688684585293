export interface IDiscount {
    discount: number;
    expiredAt: firebase.default.firestore.Timestamp;
    id: string;
    subCategories: string[]
    type: number;
    times: number;
    itemDiscount: number;
    paymentType: number;
    platform: number;
    warning: {
        payment: {
            subTitle: string;
            title: string;
        }
        type2: {
            subTitle: string;
            title: string;
        }
    }
}

export class Discount {
    discount: number;
    expiredAt: firebase.default.firestore.Timestamp;
    id: string;
    subCategories: string[]
    type: number;
    times: number;
    itemDiscount: number;
    paymentType: number;
    platform: number;
    warning: {
        payment: {
            subTitle: string;
            title: string;
        }
        type2: {
            subTitle: string;
            title: string;
        }
    }

    constructor(data: IDiscount) {
        this.discount = data.discount;
        this.expiredAt = data.expiredAt;
        this.id = data.id;
        this.subCategories = data.subCategories;
        this.type = data.type;
        this.times = data.times;
        this.itemDiscount = data.itemDiscount;
        this.paymentType = data.paymentType;
        this.platform = data.platform;
        this.warning = data.warning;
    }
}