import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MonthlyFrequencyPriceDetails, MonthlyPackage, MonthlyPackageFrequency } from '../models/subCategory.model';
import { Rating } from '../models/rating.model';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(private afs: AngularFirestore) { }

  public async getTermsAndCondition() {
    const res = await this.afs.collection('systemconfig').doc('termsConditions').get().toPromise();
    return res.data();
  }
  public getDayLabel(dateStr: string): string {
    const today = new Date().toISOString().slice(0, 10);
    const givenDate = new Date(dateStr).toISOString().slice(0, 10);
    if (givenDate === today) {
      return 'Today';
    } else if (new Date(givenDate).getTime() === new Date(today).getTime() + 1000 * 60 * 60 * 24) {
      return 'Tomorrow';
    } else {
      return '';
    }
  }

  public calculateEndTime(startTime: string, minutes?: number): string {
    if (!startTime) return "";
    let [startHours, startMinutes] = startTime.split(':');
    let meridiem = startMinutes?.split(' ')[1];
    const hours = parseInt(startHours, 10);
    const minutesToAdd = minutes ? parseInt(minutes?.toString(), 10) : 0;
    let totalMinutes = parseInt(startMinutes, 10) + minutesToAdd;
    let newHours = hours + Math.floor(totalMinutes / 60);
    totalMinutes %= 60;
    if (newHours > 12) {
      newHours -= 12;
      meridiem === 'AM' ? (meridiem = 'PM') : (meridiem = 'AM');
    }
    const formattedStartMinutes = startMinutes.padStart(2, '0');
    const formattedEndMinutes = totalMinutes.toString().padStart(2, '0');
    const period = meridiem == 'AM' && hours < 12 ? 'Morning' : (hours < 17 ? 'Afternoon' : 'Evening');
    return `${period} ${hours}:${formattedStartMinutes} ${minutes ? `- ${newHours}:${formattedEndMinutes} ${meridiem}` : ''} `;
  }

  public getFrequencyItemPrice(pkg?: MonthlyPackage, frequency?: MonthlyPackageFrequency, priceDetails?: MonthlyFrequencyPriceDetails, itemPrice: number = 0, addOnSum: number = 0, codFee: number = 0, tipFee: number = 0): number {
    if (!pkg || !frequency) return 0;
    const unitPrice = itemPrice;
    const addOnPrice = addOnSum;
    const cod = codFee;
    const tip = tipFee;
    const count = pkg.weekCount ?? 0;
    const visitsPerWeek = frequency.visitsPerWeek ?? 0;
    const res = (unitPrice + addOnPrice + cod + tip) * visitsPerWeek * count;
    const total = res - this.getDiscountAmount(priceDetails, res);
    return total;
  }

  public getDiscountAmount(priceDetails?: MonthlyFrequencyPriceDetails, price: number = 0): number {
    if (!priceDetails?.discount || !priceDetails.discountText || !priceDetails.discountType) {
      return 0;
    }
    if (priceDetails.discountType === 'percentage') {
      return price * (priceDetails.discount / 100);
    } else if (priceDetails.discountType === 'fixed') {
      return priceDetails.discount;
    }
    return 0;
  }

  public getFrequencyPriceDetails(priceDetails: any, itemId: string, freqId: string): any {
    if (priceDetails && itemId && freqId) {
      return priceDetails[itemId][freqId];
    }
    return null;
  }

  private addZeros(value: number) {
    return value.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  public getTimeLabel(time: string): string {
    if (!time) return "";
    const hour = parseInt(time.split(':')[0]);
    if (hour >= 5 && hour < 12) {
      return "Morning";
    } else if (hour >= 12 && hour < 18) {
      return "Afternoon";
    } else {
      return "Evening";
    }
  }

  public addMinutesToTime(time: string, minutesToAdd: number): string {
    if (!time || !minutesToAdd) return "";
    const [hourStr, minuteStr] = time.split(':');
    let hour = parseInt(hourStr);
    let minute = parseInt(minuteStr.substring(0, 2));
    const period = time.slice(-2);
    if (period === "PM" && hour !== 12) {
      hour += 12;
    } else if (period === "AM" && hour === 12) {
      hour = 0;
    }
    const totalMinutes = hour * 60 + minute + minutesToAdd;
    const newHour = Math.floor(totalMinutes / 60) % 24;
    const newMinute = totalMinutes % 60;
    const newPeriod = newHour >= 12 ? "PM" : "AM";
    const formattedHour = newHour === 0 ? 12 : (newHour > 12 ? newHour - 12 : newHour);
    const formattedMinute = newMinute < 10 ? `0${newMinute}` : `${newMinute}`;
    return `${formattedHour}:${formattedMinute}${newPeriod}`;
  }

  public isMatDiscountEnabled(item: any, itemMap: any) {
    return item?.cutOfPrice?.discount != null && (itemMap?.minutes >= 60 && itemMap?.minutes < 180)
  }

  public getStaffRatingType(): Rating[] {
    return [
      {key:'unlike', label: 'Bad', rating: 3,icon:'assets/images/icons/bad.png' ,rated:false},
      {key:'like', label: 'Okay', rating: 4,icon:'assets/images/icons/good.png' ,rated:false},
      {key:'better', label: 'Very Good', rating: 5 ,icon:'assets/images/icons/very-good.png',rated:false}
    ]
  }
}