import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SignInComponent } from '../core/auth/sign-in/sign-in.component';
import { IUser, User } from '../models/user.model';
import { getMobileNumber } from '../shared/logics/mobileNumberModifier';
import { DataService } from './data.service';
import { EventLogService } from './event-log.service';
import { FcmService } from './fcm.service';
import { ProfileService } from './profile.service';
import { PromotionService } from './promotion.service';
import { QuickBookingService } from './quick-booking.service';
import { SignupLeadsService } from './signup-leads.service';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  private _activeModal!: NgbModalRef;
  windowRef: any;
  user: any;
  showOtpLoader: boolean = false;
  userData?: User;
  crntUser!: User;
  private $crntUser: BehaviorSubject<User> = new BehaviorSubject(this.crntUser);
  countryCode: string = '';
  isLoginModalOpen: boolean = false;
  urlParams: any;
  urlParamSubscription: Subscription | undefined;
  recaptchaTimeout: any;
  recaptchaInitialized: boolean = false;
  private authStateTimeout: any;

  constructor(
    private modalService: NgbModal,
    private windowService: WindowService,
    private data: DataService,
    private afs: AngularFirestore,
    private router: Router,
    private promoService: PromotionService,
    private quickBookingService: QuickBookingService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private leadService: SignupLeadsService,
    private dataService: DataService,
    private eventLog: EventLogService,
    private fcmService: FcmService,
  ) {
    this.urlParamSubscription = this.dataService.crntUrlParams.subscribe(params => this.urlParams = params);
  }

  ngOnDestroy(): void {
    this.urlParamSubscription?.unsubscribe();
  }

  monitorAuthState() {
    firebase.auth().onAuthStateChanged((user) => {
      if (this.authStateTimeout) {
        clearTimeout(this.authStateTimeout);
      }
      this.authStateTimeout = setTimeout(() => {
        const uid = user?.uid;
        const localUid = localStorage.getItem('uid') || '';
        if (!uid || uid !== localUid) {
          this.forceLogout();
        }
      }, 100);
    });
  }

  /**
   * open signin modal
   * @return void
   */
  openModal(isDirectLogin?: boolean) {
    if (!this.isLoginModalOpen) {
      this.isLoginModalOpen = true;
      this._activeModal = this.modalService.open(SignInComponent, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
      });

      this._activeModal.componentInstance.isDirectLogin = isDirectLogin ?? false;
    }
  }

  /**
   * close authentication modal
   * @return void
   */
  closeModal() {
    this._activeModal.close();
    this.isLoginModalOpen = false;
    clearTimeout(this.recaptchaTimeout);
    // window.location.reload();
  }

  /* otpAuthInit() {
    firebase.auth().settings.appVerificationDisabledForTesting = true;
    this.recaptchaTimeout = setTimeout(() => {
      this.windowRef = this.windowService.windowRef;
      if (!this.windowRef?.recaptchaVerifier) {
        this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
          'callback': (response: any) => { }
        });
      }
    }, 2000);
    // this.windowRef.recaptchaVerifier.render();
  } */

  otpAuthInit() {
    const auth = firebase.auth();
    if (!this.recaptchaInitialized) {
      this.windowRef = this.windowService.windowRef;
      if (this.windowRef) {
        this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          'size': 'invisible',
        });
        this.recaptchaInitialized = true;
      }
    }
  }

  sendOtp(phoneNumber: string, countryCode: string) {
    this.data.changeShowOtpLoader(true);
    const appVerifier = this.windowRef.recaptchaVerifier;
    this.eventLog.otpInitiated(phoneNumber, countryCode);
    firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(async res => {
        this.windowRef.confirmationResult = res;
        if (this.windowRef.confirmationResult) {
          this.countryCode = countryCode;
          this.data.changeIsOtpSent(true);
        } else {
          this.data.changeIsOtpSent(false);
        }
        this.data.changeShowOtpLoader(false);
      })
      .catch(error => {
        if (error.code === 'auth/invalid-phone-number') {
          this.toastr.clear()
          this.toastr.error(
            'Invalid phone number. Please try again', 'Something went wrong!',
            { positionClass: 'toast-bottom-right' },
          )
        } else {
          alert(error);
        }
        this.data.changeShowOtpLoader(false);
        this.leadService.errorLead(error.message);
      })
  }

  verifyOtp(verifyCode: string, isDirectLogin?: boolean) {
    this.data.changeShowOtpLoader(true);
    this.windowRef.confirmationResult
      .confirm(verifyCode)
      .then(async (res: { user: any; }) => {
        this.user = res.user;
        if (this.user) {
          const guestAddress = this.dataService.getGuestAddress();
          this.data.changeIsOtpVerified(true);
          localStorage.setItem('uid', this.user.uid);
          this.userData = await this.getUserData(this.user.uid);
          this.crntUser = this.userData;
          this.eventLog.signup(this.user?.uid, this.crntUser);
          this.data.changeProfileName(this.crntUser?.displayName ?? 'My Profile');
          this.data.changeProfileImage(this.crntUser?.photoUrl ?? '/assets/images/user.svg');
          this.data.changeIsLoggedIn(true);
          this.quickBookingService.getAddress(this.user.uid);
          this.quickBookingService.getUserInfo(this.user.uid);
          var sessionStatus = sessionStorage.getItem('isSessionActive');
          var sessionDocId = sessionStorage.getItem('sessionDocId');
          if (!this.crntUser) {
            // User not in db
            this.$crntUser.next(this.user);
            this.eventLog.clevertapSignup(this.user?.uid, this.user);
            await this.storeUser(this.user);
            this.closeModal();
            if (sessionStatus === 'TRUE') {
              // this.profileService.openEditProfileModal();
              // this.router.navigate(['/profile']);
              // this.profileService.openContactDetailModal();
              if (this.user?.addresses?.length > 0) {
                this.profileService.openContactDetailModal();
              } else if (guestAddress?.id === 'GUEST01') {
                this.quickBookingService.openAddressModal(undefined, undefined, guestAddress);
              } else {
                this.quickBookingService.openAddressModal();
              }
            } else {
              // this.router.navigate(['/profile']);
              if (isDirectLogin) return;
              if (this.user?.addresses?.length > 0) {
                this.profileService.openContactDetailModal();
              } else if (guestAddress?.id === 'GUEST01') {
                this.quickBookingService.openAddressModal(undefined, undefined, guestAddress);
              } else {
                this.quickBookingService.openAddressModal();
              }
            }
          } else {
            // User already exists
            this.$crntUser.next(this.crntUser);
            this.closeModal();
            if (!this?.crntUser?.displayName || (this?.crntUser?.displayName ?? 'guest').toLowerCase() === 'guest') {
              if (sessionStatus === 'TRUE') {
                // this.profileService.openEditProfileModal();
                // this.router.navigate(['/profile']);
                // this.profileService.openContactDetailModal();
                if (guestAddress?.id === 'GUEST01') {
                  this.quickBookingService.openAddressModal(undefined, undefined, guestAddress);
                }
                else if (this.crntUser?.addresses?.length > 0) {
                  this.profileService.openContactDetailModal();
                } else {
                  this.quickBookingService.openAddressModal();
                }
              } else {
                // this.router.navigate(['/profile']);
                // if (isDirectLogin) return;
                if (guestAddress?.id === 'GUEST01') {
                  this.quickBookingService.openAddressModal(undefined, undefined, guestAddress);
                }
                if (this.user?.addresses?.length > 0) {
                  this.profileService.openContactDetailModal();
                } else {
                  this.quickBookingService.openAddressModal();
                }
              }
            }
            /* else {
              if (sessionStatus !== 'TRUE') {
                this.router.navigate(['/home']);
              }
            } */
          }
          this.leadService.verifyLead();
          const categoryDocId = sessionDocId ?? environment.maidDocId;
          if (categoryDocId && categoryDocId?.length > 6) {
            this.quickBookingService.getAvailableDiscounts(categoryDocId, true);
          }
        } else {
          this.data.changeIsOtpVerified(false);
          this.data.changeIsLoggedIn(false);
        }
        this.data.changeShowOtpLoader(false);
        this.data.changeIsOtpSent(false);
        this.promoService.getPromotionsInit();
        sessionStorage.setItem('isSessionActive', 'FALSE');
        sessionStorage.removeItem('sessionDocId');
      })
      .catch((error: any) => {
        if (error.code === 'auth/invalid-verification-code') {
          this.toastr.clear()
          this.toastr.error(
            'Invalid OTP. Please try again', 'Something went wrong!',
            { positionClass: 'toast-bottom-right' },
          )
        } else {
          alert(error);
        }
        this.data.changeIsOtpVerified(false);
        this.data.changeShowOtpLoader(false);
        this.leadService.errorLead(error.message);
      })
  }

  async getUserData(uid: string) {
    let res = await this.afs.collection('users').doc(uid).get().toPromise();
    if (!res.exists && this.user) {
      await this.storeUser(this.user);
      res = await this.afs.collection('users').doc(uid).get().toPromise();
    }
    // const user: User = new User(res.data() as IUser)
    // user.uid = res.id
    if (res.data()) {
      const _data = new User(res.data() as IUser)// res.data() as User;
      _data.docId = res.id;
      this.data.chnageCrntUser(_data);
      return _data;
    }
    else {
      localStorage.removeItem('uid');
      this.data.clearSession();
      this.data.clearLocal();
      this.quickBookingService.clearUser();
      this.data.changeIsLoggedIn(false);
      this.router.navigate(['/home']);
    }

    return {} as User;
  }

  async storeUser(data: IUser): Promise<any> {
    const phoneNo = getMobileNumber(this.countryCode, this.user?.phoneNumber?.toString());
    const res = await this.afs.collection('users', ref =>
      ref.where('phone', '==', phoneNo?.trim())
        .where('register', '==', false)
        .limit(1))
      .get().toPromise();

    let adminCreatedUsers

    // console.log('init data ',res.docs)
    if (res?.docs) {
      adminCreatedUsers = (res.docs.map(e => new User(e.data() as IUser, e?.id)))[0];
    }

    let accountId
    // console.log('init data2 ',adminCreatedUsers)
    if (adminCreatedUsers) {
      accountId = adminCreatedUsers.accountId
    } else {
      accountId = await this.getSeqAccountId();
    }
    const userData = {
      uid: data.uid,
      displayName: adminCreatedUsers?.displayName ?? data.displayName,
      email: adminCreatedUsers?.email ?? data.email,
      phone: phoneNo,//this.user.phoneNumber,
      type: "U",
      accountId: accountId,
      createdAt: adminCreatedUsers?.createdAt ?? firebase.firestore.Timestamp.now(),
      isWebUser: true,
      isYcUser: true,
      countryCode: this.countryCode,
      tags: this.urlParams ?? null,
      ...(adminCreatedUsers?.createdBy && {
        createdBy: adminCreatedUsers?.createdBy
      })
    }
    // console.log('init data3 ',userData)
    await this.afs.collection('users').doc(data.uid).set(userData, { merge: true });
  }

  async getSeqAccountId() {
    const seqIdDocRef = this.afs.collection('users').doc('userIdSequence').ref;

    return this.afs.firestore
      .runTransaction(async function (transaction) {
        const seqIdDoc: any = await transaction.get(seqIdDocRef);
        if (!(seqIdDoc.exists && seqIdDoc.data() && seqIdDoc.data()['accountId'])) {
          var randomId;
          do {
            randomId = Math.floor(Math.random() * 999);
          } while (randomId < 100);
          randomId = randomId * -1;
          return randomId;
        } else {
          const accId = seqIdDoc.data()['accountId'] as number + 1;
          transaction.update(seqIdDocRef, { accountId: accId });
          return accId;
        }
      })
      .then(function (accId) {
        return accId;
      });
  }

  getCurrentUser() {
    return this.$crntUser.asObservable();
  }

  async getUserNameByPhone(phone: string) {
    const res = await this.afs.collection('users', ref =>
      ref.where('phone', '==', phone?.trim()).limit(1))
      .get().toPromise();
    const user = (res.docs.map(e => new User(e.data() as IUser)))[0];
    return user ? user.displayName : '';
  }

  get isLoggedIn(): boolean {
    const uid = localStorage.getItem('uid') || '';
    if (uid !== null && uid !== undefined && uid !== '') {
      this.data.changeIsLoggedIn(true);
    } else {
      this.data.changeIsLoggedIn(false);
    }
    return (uid !== null && uid !== undefined && uid !== '') ? true : false;
  }

  async clearData() {
    await firebase.auth().signOut();
    this.data.changeAvailableDiscounts(false);
    this.data.changeSuperVoucher(false);
    this.data.changeVoucherConfig(false);
    this.data.clearLocal();
    this.data.clearSession();
    this.fcmService.deleteToken();
  }

  forceLogout() {
    localStorage.removeItem('uid');
    this.data.clearSession();
    this.data.clearLocal();
    this.quickBookingService.clearUser();
    this.data.changeIsLoggedIn(false);
    this.clearData();
    this.router.navigate(['/home']);
  }

}
