import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  city: string = 'dubai';

  constructor(private router: Router) { }

  getQBLandRouteLink(code: string, type: number, showService?: boolean): string {
    switch (type) {
      case 2:
        return `${this.city}/maid-service/${code}`;
      case 3:
        // return `${this.city}/ac-cleaning/${code}`;
        return showService ? `${this.city}/ac-cleaning/${code}` : 'dubai-ac-cleaning-service';
      case 5:
        return `${this.city}/expert-gardeners/${code}`;
      case 6:
        return `${this.city}/gas-booking/${code}`;
      case 7:
        return `${this.city}/pcr-test-at-home/${code}`;
      case 8:
        // return `${this.city}/deep-cleaning/${code}`;
        return showService ? `${this.city}/deep-cleaning/${code}` : 'dubai-deep-cleaning-service';
      case 9:
        return `${this.city}/disinfection-service/${code}`;
      case 10:
        return `${this.city}/express-pcr-test-at-home/${code}`;
      case 11:
        return `${this.city}/pest-control/${code}`;
      case 12:
        // return showService ? `${this.city}/maid-service/${code}` : 'dubai-maid-service';
        return `${this.city}/maid-service/${code}`;
      case 13:
        // return `${this.city}/furniture-clean/${code}`;
        return showService ? `${this.city}/furniture-cleaning/${code}` : 'dubai-furniture-cleaning-service'
      case 14:
        return `${this.city}/car-wash/${code}`;
      case 15:
        return `${this.city}/salon-men/${code}`;
      case 16:
        return `${this.city}/salon-women/${code}`;
      case 17:
        return `${this.city}/handyman-service/${code}`;
      case 18:
        return `${this.city}/plumber-service/${code}`;
      case 19:
        return `${this.city}/electrician-service/${code}`;
      case 20:
        return `${this.city}/executive-blood-tests/${code}`;
      case 21:
        return showService ? `${this.city}/ramadan-home-cleaning/${code}` : 'ramadan';
      case 22:
        return `${this.city}/zap-maid-service/${code}`;
      case 23:
        return showService ? `${this.city}/ramadan-home-cleaning/${code}` : 'ramadan';
      default:
        return '';
    }
  }

  isQuickBookingURL(): boolean {
    const url = this.router.url;
    return url.includes("/Q0") || url.includes("/addresses/select");
  }

  getExtUrl(type: number) {
    const baseUrl = 'https://yellowzap.com/services/';
    switch (type) {
      case 2:
        return `${baseUrl}maid-services-dubai/`;
      case 3:
        return `${baseUrl}air-conditioner-service-dubai/`;
      case 5:
        return `${baseUrl}ac-cleaning-service-in-dubai/` ;
      case 6:
        return null;
      case 7:
        return null;
      case 8:
        return `${baseUrl}deep-cleaning-service-in-dubai/`;
      case 9:
        return null;
      case 10:
        return null;
      case 11:
        return `${baseUrl}pest-control-in-dubai/`;
      case 12:
        return `${baseUrl}maid-services-dubai/`;
      case 13:
        return `${baseUrl}sofa-cleaning-company-in-dubai/`;
      case 14:
        return null;
      case 15:
        return null;
      case 16:
        return `${baseUrl}home-salon-service-dubai/`;
      case 17:
        return `${baseUrl}handyman-services-in-dubai/`;
      case 18:
        return `${baseUrl}plumbing-service-in-dubai/`;
      case 19:
        return `${baseUrl}electrician-in-dubai/`;
      case 20:
        return null;
      case 21:
        return null;
      case 22:
        return null;
      case 301:
        return `${baseUrl}carpentry-services-in-dubai/`;
      case 302:
        return `${baseUrl}carpet-cleaning-company-in-dubai/`;
      case 303:
        return `${baseUrl}home-cleaning-company-in-dubai/`;
      case 304:
        return `${baseUrl}cleaning-company-in-dubai/`;
      default:
        return null;
    }
  }

  getCategoryType(code: string): number {
    switch (code) {
      case 'Q023':
        return 23;
      case 'Q012':
        return 12;
      case 'Q001':
        return 3;
      case 'Q013':
        return 13;
      case 'Q008':
        return 8;
      case 'Q011':
        return 11;
      case 'Q017':
        return 17;
      case 'Q018':
        return 18;
      case 'Q019':
        return 19;
      case 'Q009':
        return 9;
      case 'Q020':
        return 20;
      case 'Q010':
        return 10;
      case 'Q016':
        return 16;
      default:
        return 0;
    }
  }

  getServiceCodeByType(type: number): string {
    switch (type) {
      case 23:
        return 'Q023';
      case 12:
        return 'Q012';
      case 3:
        return 'Q001';
      case 13:
        return 'Q013';
      case 8:
        return 'Q008';
      case 11:
        return 'Q011';
      case 17:
        return 'Q017';
      case 18:
        return 'Q018';
      case 19:
        return 'Q019';
      case 9:
        return 'Q009';
      case 20:
        return 'Q020';
      case 10:
        return 'Q010';
      case 16:
        return 'Q016';
      default:
        return "";
    }
  }
}
