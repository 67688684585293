<div class="promo-modal">
    <div class="promo-modal__header d-flex align-items-center justify-content-between">
        <div>
            <h6>Promotion Posted In</h6>
            <div>
                <p>{{promo?.subCategory?.name}}</p>
                <!-- <span>on 11 Sep 2021 at 04:30 PM</span> -->
                <span>on {{promo?.validfrom}}</span>
            </div>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()"></button>
    </div>
    <div class="promo-modal__body">
        <div class="promo-modal__data d-flex">
            <img src="{{promo?.image}}" alt="">
            <div>
                <p>{{promo?.description}}</p>
                <div class="d-flex flex-row">
                    <!-- <button class="share-btn"><span></span>Share</button> -->
                    <button *ngIf="!isQuickBooking" class="interest-btn" (click)="showInterest()">Show Interest</button>
                    <button *ngIf="isQuickBooking" class="interest-btn" (click)="bookNow()">Book Now</button>
                </div>
            </div>
        </div>
        <div class="promo-modal__vendor d-flex flex-row align-items-center">
           <!-- <img src="assets/images/vendor.png" alt=""> -->
            <div>
                <h6>{{promo?.vendorName}}</h6>
                <div class="d-flex">
                    <p><span></span><strong>{{promo?.rating?.toFixed(1)}} / 5</strong>{{promo?.numOfReviews}} Reviews</p>
                    <p>{{promo?.location?.subLocality}}{{promo?.location?.subLocality == '' ? '' : ','}} {{promo?.location?.locality}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
