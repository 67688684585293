import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentData, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IPromotion, Promotion } from '../models/promotion.model';
import { PromoDetailsComponent } from '../modules/home-page/promotions/promo-details/promo-details.component';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  subscriptions: Subscription[] = [];
  promotions: Promotion[] = [];
  private $promotions: BehaviorSubject<Array<Promotion>> = new BehaviorSubject(this.promotions);
  lastDoc?: QueryDocumentSnapshot<DocumentData>;
  private _activeModal!: NgbModalRef;

  constructor(private afs: AngularFirestore, private modalService: NgbModal) {
  }

  getPromotionsInit() {
    this.promotions = [];
    this.$promotions.next(this.promotions);
    const sub = this.afs.collection('promotions', (ref) =>
      ref
        .where('isExploreType', '==', true)
        .where('isActive', '==', true)
        .orderBy('exploreOrderBy', 'asc')
    )
      .snapshotChanges()
      .subscribe((data: any) =>
        this.updateArray(data, true)
      )
  }

  updateArray(
    data: DocumentChangeAction<DocumentData>[],
    past: boolean
  ) {
    if (data.length === 0) {
      this.promotions.push(new Promotion({ noPromotions: true } as IPromotion, ''));
      this.$promotions.next(this.promotions);
      return;
    }
    if (past) {
      this.lastDoc = data.slice(-1).pop()?.payload.doc;
    }
    const _subcategory = data.map((a) => new Promotion(a.payload.doc.data() as IPromotion, a.payload.doc.id));
    this.promotions = _subcategory;
    /* _subcategory.forEach((_incomingPromotion) => {
      const promotionIds = this.promotions.map((promo) => promo.createdAt);
      const indexOfIncomingPromotion = promotionIds.indexOf(
        _incomingPromotion.createdAt
      );
      if (indexOfIncomingPromotion >= 0) {
        this.promotions[indexOfIncomingPromotion] = _incomingPromotion;
      } else {
        if (past) {
          this.promotions.push(_incomingPromotion);
        } else {
          this.promotions.unshift(_incomingPromotion);
        }
      }
    }); */
    this.$promotions.next(this.promotions);
  }

  getSubscription() {
    return this.$promotions.asObservable();
  }

  /**
   * open promotion details modal
   * @return void
   */
  openModal(promo: Promotion) {
    this._activeModal = this.modalService.open(PromoDetailsComponent, {
      windowClass: 'promotion-modal',
      backdrop: 'static',
      keyboard: false,
      centered: true
    });
    this._activeModal.componentInstance.promo = promo;
  }

  /**
   * close promotion details modal
   * @return void
   */
  closeModal() {
    this._activeModal.close();
  }
}
