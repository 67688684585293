import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Bookings } from 'src/app/models/bookings.model';
import { Requirement } from 'src/app/models/requirement.model';
import { DataService } from 'src/app/services/data.service';
import { RouteService } from 'src/app/services/route.service';
import { StaffService } from 'src/app/services/staff.service';

@Component({
  selector: 'app-cc-payment-success',
  templateUrl: './cc-payment-success.component.html',
  styleUrls: ['./cc-payment-success.component.scss']
})
export class CcPaymentSuccessComponent implements OnInit {

  @Input() booking!: Requirement;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMob = window.innerWidth <= 700;
  }
  public isMob: boolean = false;
  constructor(
    private dataService: DataService,
    private routeService: RouteService,
    private staffService: StaffService,
    private route:ActivatedRoute,
    private router: Router,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.isMob = window.innerWidth <= 700;
  }

  quickBooking(url: string) {
    this.dataService.clearSession();
    this.activeModal.close();
    this.router.navigate([url]);
  }

  async bookAgain() {
    this.clearData();
    this.activeModal.close();
    const route = this.routeService.getQBLandRouteLink(this.routeService.getServiceCodeByType(this.booking?.subCategory.type), this.booking?.subCategory.type, true);
    this.router.navigate([route]);
  }

  clearData() {
    this.dataService.changeMaidServiceInfo(false);
    this.dataService.changeCustomerInfo(false);
    this.dataService.changeServicePolygons(false);
    this.dataService.chnageDisabledPolygons(false);
    this.dataService.changeSelectedStaffData(false);
    this.dataService.changeServiceConfig(false);
    this.dataService.changeCopiedPromo('');
    this.staffService.resetData();
  }

  getDate() {
    return moment(new Date()).format('DD MMM yy');
  }

  getPriceText(amount: number) {
    const val = 'AED ' + this.addZeros(amount);
    return val;
  }

  addZeros(value: number) {
    return value?.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  public close() {
    this.activeModal.close();
    this.router.navigate(['dubai']);
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: { status:null},
    //   queryParamsHandling: 'merge',
    // });
  }
}
