import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { IUser, User } from '../models/user.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactDetailComponent } from '../shared/components/contact-detail/contact-detail.component';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  isModalOpen: boolean = false;
  private _activeModal!: NgbModalRef;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private modalService: NgbModal
    
  ) { }

  async getUser(uid: string) {
    const res = await this.afs.collection('users').doc(uid).get().toPromise();
    const user = new User(res.data() as IUser);
    return user;
  }

  async updateProfileInfo(uid: string, data: any) {
    await this.afs.collection('users').doc(uid).set(data, { merge: true });
    // this.router.navigate(['/home']);
  }

  async addAddress(uid: string, data: any) {
    await this.afs.collection('users').doc(uid).update({
      'addresses': firebase.firestore.FieldValue.arrayUnion(data)
    });
  }

  async deleteAddress(uid: string, data: any) {
    await this.afs.collection('users').doc(uid).update({
      'addresses': firebase.firestore.FieldValue.arrayRemove(data)
    });
  }

  async setGuestDisplayName(uid: string, displayName: string) {
    const data = { displayName: displayName };
    await this.afs.collection('users').doc(uid).set(data, { merge: true });
  }

  openContactDetailModal() {
    const modalRef = this.modalService.open(ContactDetailComponent, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
      // scrollable: true
    });
  }

  closeModal() {
    this._activeModal.close();
    this.isModalOpen = false;
  }

  closeAllModals() {
    this.modalService.dismissAll();
  }

}
