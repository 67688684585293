import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FcmService } from 'src/app/services/fcm.service';
import firebase from 'firebase/app';

declare var navNew: any;

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  // isLoading: boolean | undefined;
  footerVisible: boolean = false;
  showHeader: boolean = false;
  private observer: IntersectionObserver | undefined;

  constructor(private fcmService: FcmService, private router: Router) {
    if (!firebase.app.length) {
      firebase.initializeApp(environment.firebaseConfig);
    } else {
      firebase.app();
    }

    // Spinner for lazyload modules
    // router.events.forEach((event) => {
    //   if (event instanceof RouteConfigLoadStart) {
    //     this.isLoading = true;
    //   } else if (event instanceof RouteConfigLoadEnd) {
    //     this.isLoading = false;
    //   }
    // });
    const excludedPaths: string[] = ['/holiday-homes'];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (excludedPaths.includes(event.url)) {
          this.showHeader = false;
        } else {
          this.showHeader = true;
        }
      }else if (event instanceof NavigationEnd && !excludedPaths.includes(event.url)){
        this.showHeader=true;
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState'];
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    new navNew();
    setTimeout(() => { this.startObserve() }, 200);
    setTimeout(() => { this.fcmServiceInit() }, 3000);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  startObserve() {
    var options = {
      rootMargin: '0px',
      threshold: 0.5
    };
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.target.id === "footer-wrap" && entry.isIntersecting === true) {
          this.footerVisible = true;
        }
      })
    }, options);

    var target = document.querySelector("#footer-wrap");
    if (target)
      this.observer.observe(target);
  }

  fcmServiceInit() {
    this.fcmService.init();
  }

}
