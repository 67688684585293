import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Requirement } from 'src/app/models/requirement.model';
import { MytaskService } from 'src/app/services/mytask.service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay.service';
import { getEnquiryLocation } from 'src/app/shared/logics/bookingDetailsLogic';
import { getMainStatusText, statusColor } from 'src/app/shared/logics/bookingStatusLogic';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit, OnDestroy {

  imageList: string[] = [];
  myTasksList: Requirement[] = [];
  paramId: string = '';
  task?: Requirement;
  tasks: Requirement[] = [];
  private tasksSubscription?: Subscription;

  // @Input() task!: Requirement;

  constructor(private myTaskService: MytaskService, private activatedRoute: ActivatedRoute, private spinnerService: SpinnerOverlayService) {
  }

  async ngOnInit(): Promise<void> {
    this.paramId = await this.activatedRoute.snapshot.params.id;
    this.tasksSubscription = this.myTaskService.tasks$.subscribe(
      (changes) => {
        const newTasks = changes.map((change) => {
          const data = change.payload.doc.data() as Requirement;
          const docId = change.payload.doc.id;
          data.docId = docId;
          return data;
        });
        this.tasks = [...this.tasks, ...newTasks];
        this.updateTaskDetails(this.tasks);
      }
    );
  }

  ngOnDestroy(): void {
    this.tasksSubscription?.unsubscribe();
  }

  /**
   * close task details modal
   * @return void
   */
  closeModal() {
    this.myTaskService.closeModal();
  }

  setAddress() {
    //return this.task?.location?.featureName ? this.task?.location?.featureName: 'Location not set';
    if (this.task?.address)
      return getEnquiryLocation(this.task?.address);
    else return this.task?.location?.featureName ?? '-';
  }

  getPreferredTime() {
    if (!this.task?.preferredTime) return 'Any time soon'
    return moment(this.task?.preferredTime, "HH:mm").format("LT")
  }

  getPreferredDate() {
    if (!this.task?.preferredDate) return 'Any day soon'
    return moment(this.task?.preferredDate).format('ddd, MMM DD, yyyy')
  }

  getBudgetAmount() {
    let value = this?.task?.amount ? this.task.amount : this?.task?.budget
    if (!value) return '-'
    return 'AED : ' + value.toLocaleString("en", { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  setStatus() {
    return getMainStatusText(this.task).toLowerCase()
  }

  setImageList() {
    this.imageList = this.task?.enquiryImage ?? [];
    return this.imageList;
  }

  updateTaskDetails(data: Requirement[]) {
    this.spinnerService.openLoadingModal();
    this.myTasksList = [];
    this.myTasksList = data;
    var filteredTask = this.myTasksList.filter((e) => {
      return e.docId === this.paramId;
    });
    if (filteredTask.length > 0) {
      this.task = filteredTask[0];
      this.spinnerService.closeModal();
    }
    if (filteredTask)
      this.spinnerService.closeModal();
  }

  setStatusColor(task?: Requirement) {
    // if (task?.status?.toLocaleLowerCase() === 'closed')
    //   return 'RED';
    // else
    //   return 'GREEN';
    return statusColor(task)
  }

}
