<div class="new-address-modal">
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
  <div class="new-address-modal__header d-flex align-items-center justify-content-between">
    <h6>{{modalTitle}}</h6>
    <a *ngIf="!isSearchable" (click)="closeModal()"><img src="assets/images/icons/close-drk.svg"></a>
    <a *ngIf="isSearchable" (click)="closeSearchModal()"><img src="assets/images/icons/close-drk.svg"></a>
    <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()"></button> -->
  </div>
  <div *ngIf="showMapScreen" class="locate-div d-flex justify-content-end">
    <button *ngIf="!isLocating && isScriptLoaded" (click)="geoLocate()"><img
        src="/assets/images/icons/locate.svg">Locate me</button>
    <span *ngIf="(isLocating || !isScriptLoaded)" class="loading-span">
      <img src="/assets/animations/rings.svg">
    </span>
  </div>

  <div class="new-address-modal__map"
    [ngClass]="{'new-address-modal__map-full': showMapScreen,'map-padding':!showMapScreen}">
    <div [ngClass]="{'search-location':isSearchable}">
      <span *ngIf="showMapScreen"></span>
      <input *ngIf="showMapScreen" class="d-none" type="text" inputmode="none">
      <input id="mapSearchField" [ngClass]="{'hide': !showMapScreen}" #mapSearchField type="search" (click)="search()"
        (keydown.enter)="closeSearchTab($event)"
        [placeholder]="!isSearchable?'Search for your location':'Search Location'" [value]="getFormatedAddress()">
      <a *ngIf="showMapScreen" class="clear-btn" (click)="clearSearchInput()">
        <img src="assets/images/icons/close-drk.svg"></a>
    </div>
    <div class="overlay" [ngClass]="{'map-overlay-size':!showMapScreen}" *ngIf="!showMapScreen"></div>
    <div id="gmap" [ngClass]="{'map-size':!showMapScreen}"></div>
    <div *ngIf="!showMapScreen" class="change-pin d-flex justify-content-center">
      <button (click)="goBack()">Change Location Pin</button>
    </div>
  </div>
  <div *ngIf="!showMapScreen" class="new-address-modal__address-bar d-flex align-items-center">
    <figure><img src="assets/images/icons/loc-pin.svg"></figure>
    <a class="txt">{{getFormatedAddress()}}</a>
  </div>
  <div class="new-address-modal__body" [ngClass]="{'hidden-body': showMapScreen}">
    <!-- <div *ngIf="showMapScreen" class="locate-btn" [ngClass]="{'locate-btn__full': showMapScreen}">
            <span *ngIf="!isLocating && isScriptLoaded" class="btn-span" (click)="geoLocate()">
                <img src="/assets/images/icons/locate.svg">
            </span>
            <span *ngIf="(isLocating || !isScriptLoaded)" class="loading-span">
                <img src="/assets/animations/rings.svg">
            </span>
        </div> -->
    <div class="confirm-btn" *ngIf="showMapScreen">
      <ng-container *ngIf="!isLoggedIn">
        <button class="btn add-later-btn" (click)="openSkipModal()">Add Later
        </button>
      </ng-container>
      <div class="btn" [ngClass]="{'add-later':!isLoggedIn}" (click)="confirmLocation()">
        <a>Confirm Location</a>
      </div>
    </div>
    <div class="position-relative" *ngIf="!showMapScreen">

      <div *ngIf="!hasDisplayName" class="new-address-modal__contact-form">
        <form action="" class="p-0" [formGroup]="profileForm">

          <div class="d-flex flex-column mb-4 p-0">
            <label class="contact-form-label">Your Name</label>
            <div class="d-flex">
              <select formControlName="title" [(ngModel)]="title">
                <option id="selectedTitle" *ngFor="let title of titles;" [value]="title" autocomplete="off">{{
                  title }}</option>
              </select>
              <input type="text" formControlName="displayName" class="form-control"
                placeholder="What should we call you?"
                [ngClass]="{ 'is-invalid': submitted && fval.displayName.errors }">
            </div>
            <div *ngIf="submitted && fval.displayName.errors" class="invalid-feedback d-flex">
              <div *ngIf="fval.displayName?.errors?.required" class="validation-text">* Please
                enter your name</div>
              <div *ngIf="fval.displayName?.errors?.minlength" class="validation-text">* Please
                enter a valid name</div>
            </div>
          </div>

        </form>
      </div>

      <div class="new-address-modal__landmark col-md-6">
        <!-- <label for="" class="form-label"></label> -->
        <div class="d-flex justify-content-start">
          <div *ngFor="let key of landmarkKeys">
            <input type="radio" class="btn-check" name="address" [value]="key" id="{{key}}"
              (change)="landmarkKeySelection(key)" autocomplete="off" [(ngModel)]="selectedKey">
            <label class="btn" for="{{ key }}">{{ key }}</label>
          </div>
        </div>
      </div>
      <form action="" [formGroup]="addAddressForm" class="row">
        <div class="col-md-12 d-none">
          <label for="" class="form-label">Location</label>
          <input type="text" inputmode="text" placeholder="Location" name="locationCtrl" formControlName="location">
        </div>
        <div class="col-4 col-md-3">
          <label for="" class="form-label">Flat/Villa No.</label>
          <input type="text" inputmode="text" placeholder="Flat/Villa No." name="flatNoCtrl" formControlName="flatNo">
        </div>
        <div class="col-8 col-md-9">
          <label for="" class="form-label">Building/street Name</label>
          <input type="text" inputmode="text" placeholder="Enter villa / building name" name="flatNameCtrl"
            formControlName="flatName">
        </div>
        <div class="col-md-12 mt-3 d-none d-sm-block">
          <label for="" class="form-label">Block No / Sub Community Name (Optional)</label>
          <input type="text" inputmode="text" placeholder="Ex: Block A1" name="blockNoCtrl" formControlName="blockNo">
        </div>
        <div class="d-sm-none align-items-center">
          <div class="d-flex">
            <label for="" class="form-label">Block No / Sub Community Name (Optional)</label>
          </div>
          <div>
            <input type="text" inputmode="text" placeholder="Ex: Block A1" name="blockNoCtrl" formControlName="blockNo">
          </div>
        </div>
        <div class="col-md-12 mt-3 d-none d-sm-block">
          <label for="" class="form-label">Locality / Area</label>
          <input type="text" inputmode="text" placeholder="Locality" name="localityCtrl" formControlName="locality">
        </div>
        <div class="d-sm-none align-items-center mb-0">
          <div class="d-flex">
            <label for="" class="form-label">Locality / Area</label>
          </div>
          <div>
            <input type="text" inputmode="text" placeholder="Locality" name="localityCtrl" formControlName="locality">
          </div>
        </div>
      </form>
      <!-- <div class="additional-txt d-flex mt-2 mb-2">
                <a (click)="toggleAdditionalInfo()">Additional Details (Optional)
                    <img *ngIf="!showAdditionalDetails" src="/assets/images/icons/chev-dwn-yelw.png">
                    <img *ngIf="showAdditionalDetails" src="/assets/images/icons/chev-up-yelw.png">
                </a>
            </div>
            <div [ngClass]="{'hide': !showAdditionalDetails}" class="row">
                <div class="near-landmark col-md-6">
                    <label for="" class="form-label">Nearby Landmark</label>
                    <input type="text" inputmode="text" placeholder="Enter nearby landmark" name="landmarkCtrl"
                         [(ngModel)]="nearLandmark">
                </div>
                <div class="new-address-modal__landmark col-md-6">
                    <label for="" class="form-label"></label>
                    <div class="d-flex justify-content-start mt-1">
                        <div *ngFor="let key of landmarkKeys">
                            <input type="radio" class="btn-check" name="address" [value]="key" id="{{key}}"
                                (change)="landmarkKeySelection(key)" autocomplete="off" [(ngModel)]="selectedKey">
                            <label class="btn" for="{{ key }}">{{ key }}</label>
                        </div>
                    </div>
                </div>
            </div> -->
      <div class="d-flex justify-content-center mt-4">
        <button class="bck-btn" (click)="goBack()">Back</button>
        <button (click)="currentAddress ? changeAddress() : addAddress()" [disabled]="disableSubmit">{{addButtonText}}
          <div class="loading-spinner" *ngIf="disableSubmit"></div>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="yc-modal"> -->

<!-- <div class="yc-modal__header d-flex justify-content-end">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()"></button>
    </div> -->

<!-- <div class="yc-modal__body">
        <div class="d-flex justify-content-between">
            <h5 class="text-center" style="margin-top: 8px;">Add New Address</h5>
            <button type="button" class="close close-button" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <div class="d-flex justify-content-start map-div position-relative mt-2">
            <input #mapSearchField class="search-field mt-2 position-absolute" type="text" placeholder="Search Location"
                [value]="selectedLocation">
            <div id="gmap" class=" map-container"></div> -->

<!-- <google-map id="gmap" #map class=" map-container" [zoom]="16" width="null" height="null" [center]="initialMapCoordinates"
                [options]="mapConfigs">
                <map-marker [position]="marker"></map-marker>
            </google-map> -->

<!-- </div>
        <form action="" [formGroup]="addAddressForm">
            <div style="display: none;">
                <div class="ps-2">Location</div>
                <input type="text" inputmode="text" placeholder="Location" name="locationCtrl"
                    formControlName="location">
            </div>
            <br>
            <div>
                <div class="ps-2">Flat / Villa No *</div>
                <input type="text" inputmode="text" placeholder="Enter Flat / Villa No." name="flatNoCtrl"
                    formControlName="flatNo">
            </div>
            <br>
            <div>
                <div class="ps-2">Villa / Building Name *</div>
                <input type="text" inputmode="text" placeholder="Enter Villa / Building Name" name="flatNameCtrl"
                    formControlName="flatName">
            </div>
            <br>
            <div>
                <div class="ps-2">Near by Landmark</div>
                <input type="text" inputmode="text" placeholder="Near by Landmark" name="landmarkCtrl"
                    formControlName="landmark">
            </div>
        </form>
        <div class="ps-2 mt-4">Landmark *</div>
        <div class="d-flex justify-content-start">
            <div class="position-relative ms-2" *ngFor="let key of landmarkKeys">
                <input type="radio" class="btn-check" name="address" [value]="key" id="{{key}}"
                    (change)="landmarkKeySelection(key)" autocomplete="off" [(ngModel)]="selectedKey">
                <label class="btn" for="{{ key }}">{{ key }}</label>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="yc-btn" (click)="currentAddress ? changeAddress() : addAddress()"
                [disabled]="disableSubmit">{{addButtonText}}</button>
        </div>
    </div>
</div> -->
