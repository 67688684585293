import { Clipboard } from '@angular/cdk/clipboard';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(
    private clipboard: Clipboard,
    private toastr: ToastrService
  ) { }

  getMobileOperatingSystem(): string {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent))
      return "windows";
    if (/android/i.test(userAgent))
      return "android";
    if (/iPad|iPhone|iPod/.test(userAgent))
      return "ios";
    return "unknown";
  }

  copyText(text: string) {
    this.clipboard.copy(text);
    this.toastr.clear();
    this.toastr.success(
      'Copied to clipboard', 'Well done!',
      { positionClass: 'toast-bottom-right' }
    );
  }
}
