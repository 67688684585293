import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-landing-banner',
  templateUrl: './landing-banner.component.html',
  styleUrls: ['./landing-banner.component.scss']
})
export class LandingBannerComponent implements OnInit {

  @Input() public bannerTitle!: string;
  @Input() public bannerImage!: string;
  @Input() public bannerContent!: string;
  @Input() public categoryId!: string;

  constructor(
    public activeModal: NgbActiveModal, 
    private router: Router,
    private dataService: DataService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  enquireNow() {
    this.dataService.changeCustomJobInfo('');
    this.router.navigate(['/post-task/' + this.categoryId + '/custom-job']);
    this.closeModal();
  }

}
