import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CoreService } from 'src/app/services/core.service';

@Component({
  selector: 'app-download-banner',
  templateUrl: './download-banner.component.html',
  styleUrls: ['./download-banner.component.scss']
})
export class DownloadBannerComponent implements OnInit, OnDestroy {

  showBanner: boolean = false;
  interval: any;

  constructor(
    private coreService: CoreService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const os = this.coreService.getMobileOperatingSystem();
    setTimeout(() => {
      this.showBanner = (os === 'android' || os === 'ios') ? true : false;
      if (this.showBanner) {
        this.bannerCountDown();
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  downloadApp(): void {
    const os = this.coreService.getMobileOperatingSystem();
    const androidUrl = "https://play.google.com/store/apps/details?id=com.yellochat.prod&amp;hl=en&amp;gl=US";
    const iosUrl = "https://apps.apple.com/us/app/yellochat/id1513815109";

    this.closeBanner();
    if (os === 'android')
      window.open(androidUrl, '_blank');
    else if (os === 'ios')
      window.open(iosUrl, '_blank');
    else
      this.showDownloadSec();
  }

  showDownloadSec() {
    this.router.navigate(['/home', 'downloads']);
  }

  bannerCountDown() {
    var startTime = Date.now();

    this.interval = setInterval(() => {
      var elapsedTime = Date.now() - startTime;
      const offsetTime = 500; // time taken for animations
      const displayTime = 10000 * 3;
      const el = document.getElementById('progress-percent');
      if (el && elapsedTime > offsetTime) {
        el.style.strokeDashoffset = (displayTime + (offsetTime - elapsedTime)/3).toString();
      }
      if (elapsedTime > (displayTime + offsetTime)) {
        clearInterval(this.interval);
        if (el)
          el.style.strokeDashoffset = (0).toString();
        this.showBanner = false;
      }
    }, 100);
  }

  closeBanner() {
    clearInterval(this.interval);
    this.showBanner = false;
  }

}
