<div class="yc-payment-panel">
    <div class="yc-payment-panel__row">
        <div class="yc-payment-panel__header">
            <img src="assets/images/thumbs-up.webp" alt="">
            <h4>Your Payment Is Completed!</h4>
            <p class="p1">Your payment of <strong>{{getPriceText(booking.amount)}}</strong>
                has been processed successfully</p>
            <img class="close" src="assets/images/icons/close-sm.png" alt="close.png" (click)="close()">
        </div>
        <section class="yc-payment-panel__discount">
            <div class="d-flex justify-content-center mb-1">
                <img src="assets/images/icons/reload.png" alt="">
                <h5 class="align-self-center mb-0">Get 10% discount</h5>
            </div>
            <p>Book again before 12:30 PM, {{getDate()}}</p>
            <button type="button" (click)="bookAgain()">Book Again</button>
        </section>
        <!-- <section class="yc-payment-panel__review">
            <div class="d-flex justify-content-center mb-1">
                <img src="assets/images/icons/review.png" alt="">
                <h5 class="align-self-center mb-0">Review Job</h5>
            </div>
            <p>Review your job and give rating for the staffs</p>
            <button type="button" (click)="activeModal.close()">Review me</button>
        </section> -->

        <section class="yc-payment-panel__popular" *ngIf="isMob">
                <h6>Checkout Our Other Popular Services</h6>
                <div class="d-flex scroll-x">

                    <div class="card h-100" (click)="quickBooking('/dubai/salon-women/Q016')">
                        <div class="img-div img-div--img1"><span>POPULAR</span></div>
                        <div class="card-body">
                            <h5 class="title-txt">Salon for Women</h5>
                            <p class="price-txt">Enjoy upto 15% discounts
                            </p>
                            <a>Book Now</a>
                        </div>
                    </div>

                    <div class="card h-100" (click)="quickBooking('/dubai/pest-control/Q011')">
                        <div class="img-div img-div--img2"><span>POPULAR</span></div>
                        <div class="card-body">
                            <h5 class="title-txt">Pest Control</h5>
                            <p class="price-txt">Starting from <strong>AED 134</strong></p>
                            <a>Book Now</a>
                        </div>
                    </div>

                    <div class="card h-100" (click)="quickBooking('/dubai/express-pcr-test-at-home/Q010')">
                        <div class="img-div img-div--img3">
                            <span>POPULAR</span>
                        </div>
                        <div class="card-body">
                            <h5 class="title-txt">PCR Test at Home</h5>
                            <p class="price-txt">Starting from <strong>AED 89</strong></p>
                            <a>Book Now</a>
                        </div>
                    </div>

                    <div class="card h-100" (click)="quickBooking('/dubai/handyman-service/Q017')">
                        <div class="img-div img-div--img4">
                            <span>POPULAR</span>
                        </div>
                        <div class="card-body">
                            <h5 class="title-txt">Handyman Services</h5>
                            <p class="price-txt">Starting from <strong>AED 59</strong></p>
                            <a>Book Now</a>
                        </div>
                    </div>

                    <div class="card h-100" (click)="quickBooking('/dubai/furniture-cleaning/Q013')">
                        <div class="img-div img-div--img5">
                            <span>POPULAR</span>
                        </div>
                        <div class="card-body">
                            <h5 class="title-txt">Furniture Cleaning</h5>
                            <p class="price-txt">Starting from <strong>AED 29</strong></p>
                            <a>Book Now</a>
                        </div>
                    </div>

                </div>
            </section>

    </div>
</div>