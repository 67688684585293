<div class="yc-dialog-box">
  <div class="close-btn">
    <img src="assets/images/icons/close-sm.png" alt="close.png" (click)="activeModal.close()">
  </div>
  <div class="modal-body container-fluid">
    <img class="icon" [src]="icon || 'assets/images/user.svg'" alt="icon">
    <h2>{{title}}</h2>
    <p>{{desc}}</p>
    <button class="submit-btn" type="button" (click)="submit()">{{buttonText}}</button>
    <button *ngIf="hasProceedBtn" class="proceed-btn" type="button" (click)="activeModal.close()">{{proceedBtnText}}</button>
  </div>
</div>
