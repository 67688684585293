<div class="staff-select-modal">
  <div class="staff-select-modal__header d-flex align-items-center justify-content-between">
    <h6>{{!preference? "Select Preferred Maid":'Select another maid for'}}</h6>
    <a (click)="close()"><img src="assets/images/icons/close-brwn.svg"></a>
  </div>

  <div class="modal-body container-fluid">
    <div *ngIf="preference" class="preference">
      <h6>{{preference.date|date:'dd MMMM yyyy'}}<br>{{ preference.date | date: 'EEEE \'at\' h:mm a' }}</h6>
    </div>
    <div class="search-bar d-flex align-items-center" *ngIf="!preference">
      <img src="assets/images/icons/search-blk.svg" alt="">
      <input class="d-none" type="text" inputmode="none">
      <input type="text" [(ngModel)]="searchTerm" (input)="performSearch()"
        placeholder="Search by Staff name or Staff code" />
    </div>

    <div class="scroll-sec">
      <div class="auto-assign d-flex align-items-center justify-content-between" (click)="autoAssign()"
        *ngIf="!preference">
        <div class="left-col">
          <img src="assets/images/maid-icon.webp" alt="">
          Auto assign zapper for me
        </div>
        <span></span>
      </div>

      <div *ngIf="showSearchResults" class="search-grid">
        <h4 class="book-lbl">Zapper Maids</h4>

        <div *ngIf="searchResults.length > 0" class="auto-grid">
          <div *ngFor="let staff of searchResults" class="staff" [ngClass]="{'staff__selected': staff.selected}"
            (click)="selectStaff(staff)">
            <div class="header d-flex justify-content-between">
              <span></span>
              <p>Expert</p>
            </div>
            <div class="avatar d-flex justify-content-center">
              <img src="{{staff?.profileImage ?? 'assets/images/user.svg'}}" alt="">
            </div>
            <div class="txt-col">
              <h4>{{staff?.memberName}}</h4>
              <!-- <p>Hourly Rate: AED 32</p> -->
            </div>
          </div>
        </div>
        <div *ngIf="searchResults.length === 0" class="mt-2 no-search">
          <p>No Results Found!</p>
        </div>
      </div>

      <div *ngIf="!showSearchResults" class="staff-grid">
        <h4 *ngIf="bookAgainStaff.length > 0 " class="book-lbl">Book Again - Previously Hired Zapper Maids</h4>
        <div *ngIf="bookAgainStaff.length > 0" class="auto-grid">
          <div *ngFor="let staff of bookAgainStaff" class="staff" [ngClass]="{'staff__selected': staff.selected}"
            (click)="selectStaff(staff)">
            <div class="header d-flex justify-content-between">
              <span></span>
              <p>Expert</p>
            </div>
            <div class="avatar d-flex justify-content-center">
              <img src="{{staff?.profileImage ?? 'assets/images/user.svg'}}" alt="">
            </div>
            <div class="txt-col">
              <h4>{{staff?.memberName}}</h4>
              <!-- <p>Hourly Rate: AED 32</p> -->
            </div>
          </div>
        </div>

        <h4 *ngIf="nonBookedStaff.length > 0 " class="book-lbl">Popular Zapper Maids In Your Area</h4>
        <div *ngIf="nonBookedStaff.length > 0" class="auto-grid">
          <div *ngFor="let staff of nonBookedStaff" class="staff" [ngClass]="{'staff__selected': staff.selected}"
            (click)="selectStaff(staff)">
            <div class="header d-flex justify-content-between">
              <span></span>
              <p>Expert</p>
            </div>
            <div class="avatar d-flex justify-content-center">
              <img src="{{staff?.profileImage ?? 'assets/images/user.svg'}}" alt="">
            </div>
            <div class="txt-col">
              <h4>{{staff?.memberName}}</h4>
              <!-- <p>Hourly Rate: AED 32</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>
  </div>

  <div class="modal-footer d-flex align-items-center justify-content-between">
    <div class="txt-col">
      <h4>{{selectedStaff.length}}/{{requiredStaffCount}}</h4>
      <p>Selected maids</p>
    </div>
    <button type="submit" (click)="confirm()">Confirm</button>
  </div>
</div>
