export const environment = {
  webVersion: 'YCWEB-' + require('../../package.json').version,
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyB0vFnv6StggLxpk84mFx8Q_kUEaFwvFuc",
    authDomain: "yellochat-prod.firebaseapp.com",
    databaseURL: "https://yellochat-prod.firebaseio.com",
    projectId: "yellochat-prod",
    storageBucket: "yellochat-prod.appspot.com",
    messagingSenderId: "600713672048",
    appId: "1:600713672048:web:008f0f3c2edad1bc860bb5",
    measurementId: "G-Y7P8VS6280",
    vapidKey: "BFRag_k09AVGcVkhzQ2FMA8W3vi8hd4byRZZqXWEB6x3toYt0h6M69gmF--ok7pIaDxdknn6CZvmrSapg_dHzkw"
  },
  paymentUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/hyLoPayment',
  paymentStatusUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/hyLoPaymentStatus',
  stripePaymentUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/createPayment',
  stripeMakePaymentUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/makePayment',
  stripe_pk: 'pk_live_51Jb1vIAwElwhZJ1CrY8jBA3PEWKl7FnqbKKQ7NFTqIBjj7WsQml6JF15SsV94bYUNIkTJ1JiHRBVtl1o0X1FDhzW00uHVEfUgy',
  baseUrl: 'https://yellowzap.com', // 'https://yellochat-prod.web.app',
  googleMapKey: 'AIzaSyCz2OEjrEDXhGtWBv-tXb4q0JCub24XuWs',
  mapGeocodeUrl: 'https://maps.googleapis.com/maps/api/geocode/json',
  contactYello: 'https://us-central1-yellochat-prod.cloudfunctions.net/contactYello',
  enableAdminFeatures: true,
  showLandingPopup: false,
  discountUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/discountCode',
  availableDiscountsUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/availableDiscounts',
  gtmId: 'GTM-NHBW9LT',
  maidDocId: 'rEMhqCyXcIGrcMIYI6fT',
  pixelID: '429457115537473',
  clevertapId: '485-9Z6-K76Z',
  generateReferralCodeUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/generateReferralCode',
  recentBookingReviewUrl: 'https://us-central1-yellochat-prod.cloudfunctions.net/fetchRecentBookingReview',
  staffAvailability: 'https://us-central1-yellochat-prod.cloudfunctions.net/staffAvailability'
};
