import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class SignupLeadsService {

  _docId?: string;

  constructor(private afs: AngularFirestore) { }

  reset(): void {
    this._docId = undefined;
  }

  async createLead(number: string): Promise<void> {
    const now = firebase.firestore.Timestamp.now();
    const map = {
      'platform': 1, //0 app, 1 web
      'createdAt': now,
      'signup': false,
      'sendOTP': now,
      'phone': number,
    };
    this._docId = await this.setLeads(map);
  }

  async errorLead(message: string): Promise<void> {
    const now = firebase.firestore.Timestamp.now();
    const map = {
      'updatedAt': now,
      'errors': firebase.firestore.FieldValue.arrayUnion(...[
        { 'updatedAt': now, 'message': message }
      ]),
    };
    await this.setLeads(map, this._docId);
  }

  async resendLead(): Promise<void> {
    const map = {
      'updatedAt': firebase.firestore.Timestamp.now(),
      'resend': firebase.firestore.FieldValue.increment(1),
    };
    await this.setLeads(map, this._docId);
  }

  async verifyLead(): Promise<void> {
    const map = {
      'signup': true,
      'verifyOTP': firebase.firestore.Timestamp.now(),
    };
    await this.setLeads(map, this._docId);
  }

  async changeNumberLead(number: string): Promise<void> {
    const map = {
      'updatedAt': firebase.firestore.Timestamp.now(),
      'changeNumbers': firebase.firestore.FieldValue.arrayUnion(...[number]),
    };
    await this.setLeads(map, this._docId);
    this.reset();
  }

  async setLeads(data: any, docId?: string): Promise<string> {
    var ref = this.afs.collection('signUpLeads').doc(docId);
    data['docId'] = ref.ref.id;
    await ref.set(data, { merge: true });
    return ref.ref.id;
  }

}
